import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from '@mui/material/Button';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import usersIcon from '../assets/svg/ws-b2b-icon-active-users.svg';
import pendingIcon from '../assets/svg/ws-b2b-icon-upcoming-gry.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import chevronLeftGreyIcon from '../assets/svg/ws-sml-icon-chevronleft-grey.svg';
import chevronRightIcon from '../assets/svg/ws-sml-icon-chevronright.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
	formModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		width: '705px',
		maxWidth: '705px',
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
		backgroundColor: '#ffffff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ffffff',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
		backgroundColor: '#ff0000',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#ff0000',
		},
	  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div className="modalOverlayBG">
				<Box className={classes.formModal}>
					<Container maxWidth="lg" disableGutters={true} className="form-modal-styles settings-modal">
						<Grid container spacing={2}>
							<Grid item xs={12} style={{paddingBottom: '0px'}}>
								<h3 className="modal-h3 larger-text">Add new user</h3>
							</Grid>
							<Grid item xs={6} style={{paddingTop: '0px', paddingRight: '40px'}}>
								<p className="input-label">Email address</p>
								<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

								<p className="input-label">First name</p>
								<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

								<p className="input-label">Surname</p>
								<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

								<p className="input-label">Phone number</p>
								<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

								<InputLabel className="select-heading">Office location</InputLabel>
								<Select className="shuffle-select"
									IconComponent={ExpandMoreIcon}
									  MenuProps={{
										anchorOrigin: {
										  vertical: "bottom",
										  horizontal: "left"
										},
										transformOrigin: {
										  vertical: "top",
										  horizontal: "left"
										},

									  }}
								  value="10" variant="outlined" margin="dense" fullWidth>
									<MenuItem value="10" className="selectBold">Please Select</MenuItem>
									<MenuItem value="20">Sydney</MenuItem>
									<MenuItem value="30">Melbourne</MenuItem>
									<MenuItem value="40">Adelaide</MenuItem>
								 </Select>
							</Grid>
							<Grid item xs={6} style={{paddingTop: '0px', paddingLeft: '40px'}}>
								<InputLabel className="select-heading">Department/team</InputLabel>
								<Select className="shuffle-select"
									IconComponent={ExpandMoreIcon}
									  MenuProps={{
										anchorOrigin: {
										  vertical: "bottom",
										  horizontal: "left"
										},
										transformOrigin: {
										  vertical: "top",
										  horizontal: "left"
										},

									  }}
								  value="10" variant="outlined" margin="dense" fullWidth>
									<MenuItem value="10" className="selectBold">Please Select</MenuItem>
									<MenuItem value="20">Any department/team</MenuItem>
									<MenuItem value="30" className="selectBold">Any departments/teams</MenuItem>
									<MenuItem value="40" className="selectTitle">Australia Resources Retail</MenuItem>
									<MenuItem value="50" className="selectBullet">• Retail mobile sales team</MenuItem>
									<MenuItem value="60" className="selectBullet">• Operations team</MenuItem>
									<MenuItem value="70" className="selectBullet">• Office Staff</MenuItem>
									<MenuItem value="80" className="selectBullet">• Finance team</MenuItem>
									<MenuItem value="90" className="selectTitle">Australia Resources Enterprise</MenuItem>
									<MenuItem value="100" className="selectBullet">• Business mobile sales team</MenuItem>
									<MenuItem value="120" className="selectBullet">• Office staff</MenuItem>
									<MenuItem value="130" className="selectBullet">• Finance team</MenuItem>
								 </Select>

								 <InputLabel className="select-heading">Role</InputLabel>
								 <Select className="shuffle-select"
								 	IconComponent={ExpandMoreIcon}
									   MenuProps={{
										 anchorOrigin: {
										   vertical: "bottom",
										   horizontal: "left"
										 },
										 transformOrigin: {
										   vertical: "top",
										   horizontal: "left"
										 },

									   }}
								   value="10" variant="outlined" margin="dense" fullWidth>
								 	<MenuItem value="10" className="selectBold">Please Select</MenuItem>
									 <MenuItem value="20">Office Staff</MenuItem>
									 <MenuItem value="30">Exectutive</MenuItem>
									 <MenuItem value="40">CEO</MenuItem>
								  </Select>

								  <p className="input-label" style={{ marginBottom: '7px' }}>Expire access</p>
									<FormControl component="fieldset">

									  <RadioGroup row aria-label="expires" name="row-radio-buttons-group">
										<FormControlLabel value="never" control={
										<Radio className={classes.root}
											  disableRipple
											  color="default"
											  checkedIcon={<span className={clsx(classes.icon)} />}
											  icon={<span className={classes.icon} />} />} label="Never" />

										<FormControlLabel value="setdate" control={
										<Radio className={classes.root}
											  disableRipple
											  color="default"
											  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
											  icon={<span className={classes.icon} />} />} className="radio-checked" checked label="Set date" />

									  </RadioGroup>

									</FormControl>
							</Grid>
						</Grid>
						<Grid container spacing={2} direction="row" alignItems="center" style={{marginTop: '17px'}}>
							<Grid item xs={3}>
								<Box display="flex" justifyContent="flex-start">
									<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large">Cancel</Button>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" justifyContent="center">

								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box display="flex" justifyContent="flex-end">
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" disabled>Add</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</div>

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Manage all <br /> Shuffle users</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
								<TextField id="outlined-basic" defaultValue="Search name" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
									InputProps={{
										className: "shuffleInput",
  										startAdornment: (
    										<InputAdornment position="start">
      										<img className="search-field-icon" src={searchFieldIcon} />
    										</InputAdornment>
   										)
  										}} />
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper">
								<Grid item xs={6}>
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Add new user</Button>
								</Grid>
								<Grid item xs={6}>
									<Button className="outlined-mono" variant="outlined" color="secondary" style={{ marginTop: '15px' }} size="large">Bulk add users</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper settings-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="tabs-panel">
								<div className="tab selected-tab active-users-tab">
									<img className="tab-icon icon-active-users" src={usersIcon} alt="Active Users" />
									<h4>Active Users</h4>
									<p>680 parkers</p>
								</div>

								<div className="tab pending-approval-tab">
									<img className="tab-icon icon-pending-approval" src={pendingIcon} alt="Pending approval" />
									<h4>Pending approval</h4>
									<p>7 parkers</p>
								</div>
							</Grid>

							<Grid item xs={12} className="content-panel no-border-top">
								<Grid container spacing={0}>
									<Grid item xs={9}>
										<h3 className="padding-top-20">680 parkers</h3>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading">Department/team</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Any department/team</MenuItem>
											<MenuItem value="20" className="selectBold">Any departments/teams</MenuItem>
											<MenuItem value="30" className="selectTitle">Australia Resources Retail</MenuItem>
											<MenuItem value="40" className="selectBullet">• Retail mobile sales team</MenuItem>
											<MenuItem value="50" className="selectBullet">• Operations team</MenuItem>
											<MenuItem value="60" className="selectBullet">• Office Staff</MenuItem>
											<MenuItem value="70" className="selectBullet">• Finance team</MenuItem>
											<MenuItem value="80" className="selectTitle">Australia Resources Enterprise</MenuItem>
											<MenuItem value="90" className="selectBullet">• Business mobile sales team</MenuItem>
											<MenuItem value="100" className="selectBullet">• Office staff</MenuItem>
											<MenuItem value="110" className="selectBullet">• Finance team</MenuItem>
										 </Select>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12} className={classes.gridTopMargin}>
										<table id="transactions">
											<tbody>
											<tr>
												<th>Parker</th>
												<th>Email</th>
												<th>Department</th>
												<th>Team</th>
												<th>Last Active</th>
										  	</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald</a></td>
												<td>jeremy.fitzgerald@company.com</td>
												<td>Australia Resources Retail</td>
												<td>National Technology</td>
												<td>1 August 2021</td>
											</tr>
											</tbody>
										</table>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<div className="pagination">
											<a className="pagination-link first-page" href="#"><img className="pagination-left-icon left-grey" src={chevronLeftGreyIcon} /></a>
											<a className="pagination-link current-page" href="#">1</a>
											<a className="pagination-link next-page" href="#">2</a>
											<a className="pagination-link next-page" href="#">3</a>
											<a className="pagination-link next-page" href="#">4</a>
											<a className="pagination-link next-page" href="#">5</a>
											<a className="pagination-link more-pages" href="#">...</a>
											<a className="pagination-link last-page" href="#"><img className="pagination-right-icon right-black" src={chevronRightIcon} /></a>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="settings-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

