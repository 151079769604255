import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import OperationsHeader from "../components/operations-header";
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';
import closeIcon from '../assets/svg/ws-ui-icon-close-x-black.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleButtonModal: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#ff0000',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleButtonLeft: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: 'white',
		fontSize: '0.875rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		minWidth: '160px',
	},
	shuffleButtonRight: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: 'white',
		fontSize: '0.875rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		minWidth: '140px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
	formModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		width: '440px',
		maxWidth: '440px',
		textAlign: 'left',
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
		backgroundColor: '#ffffff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ffffff',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
		backgroundColor: '#ff0000',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#ff0000',
		},
	  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OperationsHeader />
				
			<div className="modalOverlayBG">
				<Box className={classes.formModal}>
					<Container maxWidth="lg" disableGutters={true} className="form-modal-styles change-status-modal">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<img className="icon-close-modal mobile-only" src={closeIcon} alt="close" />
								<h3 className="modal-h3 larger-text">Open car park gate</h3>
								<p>You can open a gate remotely for this user.<br />Their entry status will also be updated.</p>
									<div className="gate-select">
										<InputLabel className="select-heading">Which gate is the user waiting at?</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },
										
											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Select...</MenuItem>
											<MenuItem value="20">Gate 1</MenuItem>
											<MenuItem value="30">Gate 2</MenuItem>
										 </Select>
									</div>
									
									<div className="gate-radios">
										<div className="gate-radio-wrapper">
											<h4 className="reason-header">Select a reason</h4>
											<FormControl component="fieldset">
										  	<RadioGroup className="modal-checkboxes-gate">
												<div className="left-column">
													<FormControlLabel value="option1" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 1"
														labelPlacement="end" />
														
													<FormControlLabel value="option2" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 2"
														labelPlacement="end" />
													
													<FormControlLabel value="option3" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 3"
														labelPlacement="end" />
												</div>
												<div className="right-column">
													<FormControlLabel value="option4" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 4"
														labelPlacement="end" />
													
													<FormControlLabel value="option5" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 5"
														labelPlacement="end" />
													
													<FormControlLabel value="option6" control={<Radio
														className={classes.root}
														disableRipple
														color="default"
														checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
														icon={<span className={classes.icon} />}
														/>}
														label="Reason 6"
														labelPlacement="end" />
												</div>
										  	</RadioGroup>
											</FormControl>
										</div>
									</div>
							</Grid>
						</Grid>
						
						<Grid container spacing={2} style={{ paddingTop: '20px' }} className="anchored-wrapper">
							<Grid item xs={6} className="desktop-only">
								<Box display="flex" justifyContent="flex-start">
									<Button className={classes.shuffleButtonModal} variant="outlined" color="primary" size="large" style={{ width: '160px' }}>Cancel</Button>
								</Box>
							</Grid>
							<Grid item xs={6} className="mobile-fullwidth-button">
								<Box display="flex" justifyContent="flex-end">
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" style={{ width: '160px' }} disabled>Open gate</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</div>	
				
			<div className="page-content-wrapper ondemand-section">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Sam McMillan</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p className="entry-status">Entry Status: Inside</p>
									<p className="entry-status-detail">The user is inside the correct car park area.</p>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper right-aligned">
								<Grid item xs={12} className="inline-buttons">
									<Button className={classes.shuffleButtonLeft} variant="contained" color="primary" size="large">Change entry status</Button>
									<Button className={classes.shuffleButtonRight} variant="outlined" color="secondary" size="large">Open gate</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-10 padding-top-5">On-demand booking</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail first-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>User</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>Sam McMillan</td>
													</tr>
													<tr>
														<td>sam.mcmillan@company.com.au</td>
													</tr>
													<tr>
														<td>Australia Resources Retail (Office staff)</td>
													</tr>
													<tr>
														<td>Executive</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>
												
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail last-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>Booking</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>George Street Office</td>
													</tr>
													<tr>
														<td>Staff Parking</td>
													</tr>
													<tr>
														<td>Wed 30 August 6:00am - Thu 31 August 6:00pm</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>

											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="position-bottom light-bg modal-open">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

