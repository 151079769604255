import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import calendarIcon from '../assets/svg/ws-ui-icon-calendar-grey.svg';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';


const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '200px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
		backgroundColor: '#ffffff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ffffff',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
		backgroundColor: '#ff0000',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#ff0000',
		},
	  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Sam McMillan</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p>Last active today</p>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper right-aligned">
								<Grid item xs={12}>
									<Button className="outlined-mono" variant="outlined" color="secondary" size="large" style={{ width: '140px' }}>Delete User</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-20">Assigned parking</h3>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12} className="assigned-parking-details">
										<p className="assigned-detail"><strong>Reserved parking</strong> George Street Office - Level 1 - Zone C - Bay 76</p>
										<a href="" className="assigned-edit-link">Edit assigned parking</a>
									</Grid>
								</Grid>
								<Grid container spacing={0} className="manage-assigned-wrapper">
									<Grid item xs={5}>
										<table id="bay-use-table">
											<tr>
												<td>Starts</td>
												<td><strong>6 October 2021</strong></td>
											</tr>
											<tr>
												<td>Expires</td>
												<td><strong>Never</strong></td>
											</tr>
										</table>
									</Grid>
									<Grid item xs={7}>
										<div className="day-usage-wrapper">
											<ul className="day-usage-menu">
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">M</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">T</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">W</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">T</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">F</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">S</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">S</a>
													<p className="day-usage-text">All day</p>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid container spacing={0} className="assigned-sub-section">
									<Grid item xs={12}>
										<h3 className="padding-bottom-20">Recent bookings</h3>
										<p>This user doesn’t have any booking history.</p>
									</Grid>
								</Grid>
								<Grid container spacing={0} className="assigned-sub-section">
									<Grid item xs={12}>
										<h3 className="padding-bottom-20">User profile</h3>
									</Grid>
									<Grid item xs={6}>
										<p className="input-label">Email address</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="sam.mcmillan@company.com" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

										<p className="input-label">First name</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="Sam" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

										<p className="input-label">Surname</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="McMillan" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

										<p className="input-label">Phone number</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="04012345678" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />

										<InputLabel className="select-heading">Office location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },

											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Sydney</MenuItem>
											<MenuItem value="20">Melbourne</MenuItem>
											<MenuItem value="30">Adelaide</MenuItem>
										</Select>
									</Grid>
									<Grid item xs={6}>
										<InputLabel className="select-heading">Department/team</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },

											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Australia Resources Retail &gt; Operations team</MenuItem>
											<MenuItem value="30" className="selectTitle">Australia Resources Retail</MenuItem>
											<MenuItem value="40" className="selectBullet">• Retail mobile sales team</MenuItem>
											<MenuItem value="50" className="selectBullet">• Operations team</MenuItem>
											<MenuItem value="60" className="selectBullet">• Office Staff</MenuItem>
											<MenuItem value="70" className="selectBullet">• Finance team</MenuItem>
											<MenuItem value="80" className="selectTitle">Australia Resources Enterprise</MenuItem>
											<MenuItem value="90" className="selectBullet">• Business mobile sales team</MenuItem>
											<MenuItem value="100" className="selectBullet">• Office staff</MenuItem>
											<MenuItem value="110" className="selectBullet">• Finance team</MenuItem>
										 </Select>

										 <InputLabel className="select-heading">Role</InputLabel>
										 <Select className="shuffle-select"
										 	IconComponent={ExpandMoreIcon}
											 MenuProps={{
											   anchorOrigin: {
												 vertical: "bottom",
												 horizontal: "left"
											   },
											   transformOrigin: {
												 vertical: "top",
												 horizontal: "left"
											   },

											 }}
										 value="10" variant="outlined" margin="dense" fullWidth>
											 <MenuItem value="10" className="selectBold">Office Staff</MenuItem>
											 <MenuItem value="20">Exectutive</MenuItem>
											 <MenuItem value="30">CEO</MenuItem>
										  </Select>

										  <p className="input-label" style={{ marginBottom: '7px' }}>Expire access</p>
										  <FormControl component="fieldset">

											<RadioGroup row aria-label="expires" name="row-radio-buttons-group">
											  <FormControlLabel value="never" control={
											  <Radio className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon)} />}
													icon={<span className={classes.icon} />} />} label="Never" />

											  <FormControlLabel value="setdate" control={
											  <Radio className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />} />} className="radio-checked" checked label="Set date" />

											</RadioGroup>

										  </FormControl>

										  <TextField id="outlined-basic" defaultValue="4 August 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										  InputProps={{
										  className: "shuffleInputIcon",
											endAdornment: (
											  <InputAdornment position="end">
												<img className="input-calendar-icon" src={calendarIcon} />
											  </InputAdornment>
											 )
											}} />
									</Grid>
								</Grid>
								<Grid container spacing={0} className="assigned-sub-section">
									<Grid item xs={12}>
										<h3 className="padding-bottom-20">Vehicles</h3>
									</Grid>
									<Grid item xs={6}>
										<p className="input-label">Vehicle registration</p>
										<TextField id="vehicle-field" InputProps={{ className: "shuffleInput" }} defaultValue="1ABC213" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" />
										<a href="" className="remove-vehicle"></a>
										<a href="" className="add-vehilce-link">Add another vehicle</a>
									</Grid>
									<Grid item xs={6}>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '40px' }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Save changes</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="assigned-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

