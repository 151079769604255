import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import chevronLeftGreyIcon from '../assets/svg/ws-sml-icon-chevronleft-grey.svg';
import chevronRightIcon from '../assets/svg/ws-sml-icon-chevronright.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
	shuffleAlert: {
		backgroundColor: '#009273',
		color: 'white',
		borderRadius: '8px',
		top: '10px',
		position: 'absolute',
		padding: '14px 16px',
	}
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<Box className={classes.shuffleAlert} zIndex="tooltip">
				<p className="success-text">5 users have been unassigned</p>
			</Box>

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Manage parkers</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper full-width">
								<Grid item xs={12}>
									<TextField id="outlined-basic" defaultValue="Search name" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										InputProps={{
											className: "shuffleInput",
  											startAdornment: (
    											<InputAdornment position="start">
      											<img className="search-field-icon" src={searchFieldIcon} />
    											</InputAdornment>
   											)
  											}} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<h3 className="padding-top-20">74 parkers</h3>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading">Parker type</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },

											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Any Parker type</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading">Location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },

											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Any Location</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12} className={classes.gridTopMargin}>
										<table id="transactions">
											<tr>
												<th>Parker(s)</th>
												<th>Location</th>
												<th>Type</th>
												<th>Bays</th>
										  	</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
											<tr>
												<td className="transaction-id"><a href="#">Jeremy Fitzgerald + 4 more</a></td>
												<td>George Street Car Park</td>
												<td>Unreserved</td>
												<td>Level 1, Zone C, Bay 76</td>
											</tr>
										</table>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<div className="pagination">
											<a className="pagination-link first-page" href="#"><img className="pagination-left-icon left-grey" src={chevronLeftGreyIcon} /></a>
											<a className="pagination-link current-page" href="#">1</a>
											<a className="pagination-link next-page" href="#">2</a>
											<a className="pagination-link next-page" href="#">3</a>
											<a className="pagination-link next-page" href="#">4</a>
											<a className="pagination-link next-page" href="#">5</a>
											<a className="pagination-link more-pages" href="#">...</a>
											<a className="pagination-link last-page" href="#"><img className="pagination-right-icon right-black" src={chevronRightIcon} /></a>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="assigned-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

