import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainNavigation from './pages/Main-Navigation'
import LoginVariantOne from './pages/Login-Variant-One'
import LoginVariantTwo from './pages/Login-Variant-Two'
import LoginVariantThree from './pages/Login-Variant-Three'
import LoginVariantFour from './pages/Login-Variant-Four'
import LoginVariantFive from './pages/Login-Variant-Five'
import LoginVariantSix from './pages/Login-Variant-Six'
import LoginVariantSeven from './pages/Login-Variant-Seven'
import LoginVariantEight from './pages/Login-Variant-Eight'
import HomeDashboard from './pages/Home-Dashboard'
import HomeDashboardLoading from './pages/Home-Dashboard-Loading'
import AssignedLanding from './pages/Assigned-Landing'
import AssignedManageParkers from './pages/Assigned-Manage-Parkers'
import AssignedManageSingleBay from './pages/Assigned-Manage-Single-Bay'
import AssignedManageParkersUnassignSuccess from './pages/Assigned-Manage-Parkers-Unassign-Success'
import AssignedManageSingleBayConfirmation from './pages/Assigned-Manage-Single-Bay-Confirmation'
import AssignedManageSingleParkers from './pages/Assigned-Manage-Single-Parkers'
import AssignedManageSharedBay from './pages/Assigned-Manage-Shared-Bay'
import AssignedManageSharedBayConfirmation from './pages/Assigned-Manage-Shared-Bay-Confirmation'
import AssignedManageSharedParkers from './pages/Assigned-Manage-Shared-Parkers'
import AssignedAssignBaySearchUsers from './pages/Assigned-Assign-Bay-Search-Users'
import AssignedAssignBayUserAdded from './pages/Assigned-Assign-Bay-User-Added'
import AssignedAssignBaySingleUserDaysSelected from './pages/Assigned-Assign-Bay-Single-User-Days-Selected'
import AssignedAssignBaySingleUserHourlyAvails from './pages/Assigned-Assign-Bay-Single-User-Hourly-Avails'
import AssignedAssignBaySingleUserHourlyAvailsExpirySelect from './pages/Assigned-Assign-Bay-Single-User-Hourly-Avails-Expiry-Select'
import AssignedAssignABay from './pages/Assigned-Assign-A-Bay'
import AssignedAssignBayUserAddedTooltip from './pages/Assigned-Assign-Bay-User-Added-Tooltip'
import AssignedAssignBayEditStepOne from './pages/Assigned-Assign-Bay-Edit-Step-One'
import AssignedAssignBayEditStepTwo from './pages/Assigned-Assign-Bay-Edit-Step-Two'
import AssignedShareBayOption from './pages/Assigned-Share-Bay-Option'
import AssignedShareBayModal from './pages/Assigned-Share-Bay-Modal'
import AssignedShareBayModalUserAdded from './pages/Assigned-Share-Bay-Modal-User-Added'
import AssignedShareBayModalEditShare from './pages/Assigned-Share-Bay-Modal-Edit-Share'
import AssignedBayShared from './pages/Assigned-Bay-Shared'
import OnDemandLanding from './pages/On-Demand-Landing'
import OnDemandTransactions from './pages/On-Demand-Transactions'
import OnDemandTransactionsExpanded from './pages/On-Demand-Transactions-Expanded'
import OnDemandTransactionsFilterList from './pages/On-Demand-Transactions-Filter-List'
import OnDemandTransactionsCustomDateRange from './pages/On-Demand-Transactions-Custom-Date-Range'
import OnDemandTransactionsCustomDateRangePicker from './pages/On-Demand-Transactions-Custom-Date-Range-Picker'
import OnDemandTransactionsListSearching from './pages/On-Demand-Transactions-List-Searching'
import OnDemandTransactionDetail from './pages/On-Demand-Transaction-Detail'
import OnDemandTransactionDetailCompleted from './pages/On-Demand-Transaction-Detail-Completed'
import OnDemandTransactionDetailWindowClosed from './pages/On-Demand-Transaction-Detail-Window-Closed'
import OnDemandTransactionDetailRefundModal from './pages/On-Demand-Transaction-Detail-Refund-Modal'
import OnDemandTransactionDetailRefundSuccess from './pages/On-Demand-Transaction-Detail-Refund-Success'
import OnDemandTransactionDetailRefundError from './pages/On-Demand-Transaction-Detail-Refund-Error'
import OnDemandTransactionDetailCancelModalA from './pages/On-Demand-Transaction-Detail-Cancel-Modal-A'
import OnDemandTransactionDetailCancelModalB from './pages/On-Demand-Transaction-Detail-Cancel-Modal-B'
import OnDemandTransactionCancelled from './pages/On-Demand-Transaction-Cancelled'
import OnDemandBookingWhoFor from './pages/On-Demand-Booking-Who-For'
import OnDemandBookingWhoForNoGuestAvailable from './pages/On-Demand-Booking-Who-For-No-Guest-Available'
import OnDemandBookingWhereWhen from './pages/On-Demand-Booking-Where-When'
import OnDemandBookingChooseProduct from './pages/On-Demand-Booking-Choose-Product'
import OnDemandBookingAdmin from './pages/On-Demand-Booking-Admin'
import OnDemandBookingNoPrice from './pages/On-Demand-Booking-No-Price'
import OnDemandBookingNoAvailability from './pages/On-Demand-Booking-No-Availability'
import OnDemandBookingClash from './pages/On-Demand-Booking-Clash'
import OnDemandBookingConfirmation from './pages/On-Demand-Booking-Confirmation'
import OnDemandChangeBookingWhereWhen from './pages/On-Demand-Change-Booking-Where-When'
import OnDemandChangeBookingChooseProduct from './pages/On-Demand-Change-Booking-Choose-Product'
import OnDemandChangeBookingNoCostChange from './pages/On-Demand-Change-Booking-No-Cost-Change'
import OnDemandChangeBookingNoAdditionalCharge from './pages/On-Demand-Change-Booking-No-Additional-Charge'
import OnDemandChangeBookingGapRefund from './pages/On-Demand-Change-Booking-Gap-Refund'
import OnDemandChangeBookingUpdatedTransaction from './pages/On-Demand-Change-Booking-Updated-Transaction'
import OnDemandProductsCapacities from './pages/On-Demand-Products-Capacities'
import OnDemandBlockOutDates from './pages/On-Demand-Block-Out-Dates'
import OnDemandBlockOutDatesModal from './pages/On-Demand-Block-Out-Dates-Modal'
import OnDemandBlockOutDatesModalDateSelect from './pages/On-Demand-Block-Out-Dates-Modal-Date-Select'
import OnDemandBlockOutDatesModalConfirmation from './pages/On-Demand-Block-Out-Dates-Modal-Confirmation'
import OnDemandBlockOutDatesModalSingleDept from './pages/On-Demand-Block-Out-Dates-Modal-Single-Dept'
import OnDemandBlockOutDatesModalMultiDept from './pages/On-Demand-Block-Out-Dates-Modal-Multi-Dept'
import OnDemandBlockOutDatesModalMultiDeptAllSelected from './pages/On-Demand-Block-Out-Dates-Modal-Multi-Dept-All-Selected'
import OnDemandBlockOutDatesModalMultiDeptSpecificSelected from './pages/On-Demand-Block-Out-Dates-Modal-Multi-Dept-Specific-Selected'
import OnDemandBlockOutDatesModalMultiDeptOneSelected from './pages/On-Demand-Block-Out-Dates-Modal-Multi-Dept-One-Selected'
import OnDemandSingleProductSite from './pages/On-Demand-Single-Product-Site'
import OnDemandMultiProductSite from './pages/On-Demand-Multi-Product-Site'
import OnDemandMultiSiteMultiDept from './pages/On-Demand-Multi-Site-Multi-Dept'
import GuestLanding from './pages/Guest-Landing'
import GuestChooseDate from './pages/Guest-Choose-Date'
import GuestChooseBayReserved from './pages/Guest-Choose-Bay-Reserved'
import GuestChooseBayNoAvails from './pages/Guest-Choose-Bay-No-Avails'
import GuestChooseBayUnreserved from './pages/Guest-Choose-Bay-Unreserved'
import GuestChooseBayUnreservedNoAvails from './pages/Guest-Choose-Bay-Unreserved-No-Avails'
import GuestAvailabilityCalendarModal from './pages/Guest-Availability-Calendar-Modal'
import UtilisationFullMonth from './pages/Utilisation-Full-Month'
import UtilisationPartialMonth from './pages/Utilisation-Partial-Month'
import UtilisationMultiTeam from './pages/Utilisation-Multi-Team'
import UtilisationMultiTeamTeamSelect from './pages/Utilisation-Multi-Team-Team-Select'
import UtilisationFilteredByTeam from './pages/Utilisation-Filtered-By-Team'
import SettingsLanding from './pages/Settings-Landing'
import SettingsManageUsers from './pages/Settings-Manage-Users'
import SettingsManageUsersFilterDropdown from './pages/Settings-Manage-Users-Filter-Dropdown'
import SettingsManageUserAssigned from './pages/Settings-Manage-User-Assigned'
import SettingsManageUserRecentBookings from './pages/Settings-Manage-User-Recent-Bookings'
import SettingsSelfRegistration from './pages/Settings-Self-Registration'
import SettingsSelfRegistrationDisabled from './pages/Settings-Self-Registration-Disabled'
import SettingsAddDomain from './pages/Settings-Add-Domain'
import SettingsAddUserModalBlank from './pages/Settings-Add-User-Modal-Blank'
import SettingsAddUserModalComplete from './pages/Settings-Add-User-Modal-Complete'
import SettingsBulkAddUsersModalBlank from './pages/Settings-Bulk-Add-Users-Modal-Blank'
import SettingsBulkAddUsersModalUpload from './pages/Settings-Bulk-Add-Users-Modal-Upload'
import SettingsBulkAddUsersModalBlankError from './pages/Settings-Bulk-Add-Users-Modal-Blank-Error'
import SelfRegistrationMultiDeptModalComplete from './pages/Self-Registration-Multi-Dept-Modal-Complete'
import SelfRegistrationMultiDeptModalError from './pages/Self-Registration-Multi-Dept-Modal-Error'
import SelfRegistrationSingleDeptModalComplete from './pages/Self-Registration-Single-Dept-Modal-Complete'
import SelfRegistrationSingleDeptModalError from './pages/Self-Registration-Single-Dept-Modal-Error'
import SelfRegistrationModalAcceptedPendingVerification from './pages/Self-Registration-Modal-Accepted-Pending-Verification'
import SelfRegistrationModalAdminReview from './pages/Self-Registration-Modal-Admin-Review'
import SelfRegistrationModalAccepted from './pages/Self-Registration-Modal-Accepted'
import SelfRegistrationModalNotAvailable from './pages/Self-Registration-Modal-Not-Available'
import ControlCentreLanding from './pages/Control-Centre-Landing'
import ControlCentreSearch from './pages/Control-Centre-Search'
import ControlCentreNoResults from './pages/Control-Centre-No-Results'
import ControlCentreOnDemandBooking from './pages/Control-Centre-On-Demand-Booking'
import ControlCentreGuestBooking from './pages/Control-Centre-Guest-Booking'
import ControlCentreAssignedBooking from './pages/Control-Centre-Assigned-Booking'
import ControlCentreChangeEntryStatusModalInside from './pages/Control-Centre-Change-Entry-Status-Modal-Inside'
import ControlCentreChangeEntryStatusModalNestedInside from './pages/Control-Centre-Change-Entry-Status-Modal-Nested-Inside'
import ControlCentreChangeEntryStatusModalNestedOutside from './pages/Control-Centre-Change-Entry-Status-Modal-Nested-Outside'
import ControlCentreChangeEntryStatusModalNestedTransitingIn from './pages/Control-Centre-Change-Entry-Status-Modal-Nested-Transiting-In'
import ControlCentreChangeEntryStatusModalNestedTransitingOut from './pages/Control-Centre-Change-Entry-Status-Modal-Nested-Transiting-Out'
import ControlCentreChangeEntryStatusModalNestedOutside2 from './pages/Control-Centre-Change-Entry-Status-Modal-Nested-Outside-2'
import ControlCentreStatusChangeSuccess from './pages/Control-Centre-Status-Change-Success'
import ControlCentreStatusChangeError from './pages/Control-Centre-Status-Change-Error'
import ControlCentreOpenGateModal from './pages/Control-Centre-Open-Gate-Modal'
import ControlCentreOpenGateModalSelected from './pages/Control-Centre-Open-Gate-Modal-Selected'
import ControlCentreOpenGateSuccess from './pages/Control-Centre-Open-Gate-Success'
import ControlCentreOpenGateError from './pages/Control-Centre-Open-Gate-Error'
import ControlCentreForceGateOpen from './pages/Control-Centre-Force-Gate-Open'
import ControlCentreForceGateOpenOpened from './pages/Control-Centre-Force-Gate-Open-Opened'
import ControlCentreForceGateOpenError from './pages/Control-Centre-Force-Gate-Open-Error'
import NavigationMobile from './pages/Navigation-Mobile'
import Home from './pages/Home'

interface IProps {}

const RoutesUtil: React.FC<IProps> = (props) => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/main-navigation" element={<MainNavigation />} />

          <Route path="/login-variant-one" element={<LoginVariantOne />} />
          <Route path="/login-variant-two" element={<LoginVariantTwo />} />
          <Route path="/login-variant-three" element={<LoginVariantThree />} />
          <Route path="/login-variant-four" element={<LoginVariantFour />} />
          <Route path="/login-variant-five" element={<LoginVariantFive />} />
          <Route path="/login-variant-six" element={<LoginVariantSix />} />
          <Route path="/login-variant-seven" element={<LoginVariantSeven />} />
          <Route path="/login-variant-eight" element={<LoginVariantEight />} />
          <Route path="/home-dashboard" element={<HomeDashboard />} />
          <Route path="/home-dashboard-loading" element={<HomeDashboardLoading />} />
          <Route path="/assigned-landing" element={<AssignedLanding />} />
          <Route path="/assigned-manage-parkers" element={<AssignedManageParkers />} />
          <Route path="/assigned-manage-single-bay" element={<AssignedManageSingleBay />} />
          <Route path="/assigned-manage-parkers-unassign-success" element={<AssignedManageParkers />} />
          <Route path="/assigned-manage-single-bay-confirmation" element={<AssignedManageSingleBayConfirmation />} />
          <Route path="/assigned-manage-single-parkers" element={<AssignedManageSingleParkers />} />
          <Route path="/assigned-manage-shared-bay" element={<AssignedManageSharedBay />} />
          <Route path="/assigned-manage-shared-bay-confirmation" element={<AssignedManageSharedBayConfirmation />} />
          <Route path="/assigned-manage-shared-parkers" element={<AssignedManageSharedParkers />} />
          <Route path="/assigned-assign-bay-search-users" element={<AssignedAssignBaySearchUsers />} />
          <Route path="/assigned-assign-bay-user-added" element={<AssignedAssignBayUserAdded />} />
          <Route
            path="/assigned-assign-bay-single-user-days-selected"
            element={<AssignedAssignBaySingleUserDaysSelected />}
          />
          <Route
            path="/assigned-assign-bay-single-user-hourly-avails"
            element={<AssignedAssignBaySingleUserHourlyAvails />}
          />
          <Route
            path="/assigned-assign-bay-single-user-hourly-avails-expiry-select"
            element={<AssignedAssignBaySingleUserHourlyAvailsExpirySelect />}
          />
          <Route path="/assigned-assign-a-bay" element={<AssignedAssignABay />} />
          <Route path="/assigned-assign-bay-user-added-tooltip" element={<AssignedAssignBayUserAddedTooltip />} />
          <Route path="/assigned-assign-bay-edit-step-one" element={<AssignedAssignBayEditStepOne />} />
          <Route path="/assigned-assign-bay-edit-step-two" element={<AssignedAssignBayEditStepTwo />} />
          <Route path="/assigned-share-bay-option" element={<AssignedShareBayOption />} />
          <Route path="/assigned-share-bay-modal" element={<AssignedShareBayModal />} />
          <Route path="/assigned-share-bay-modal-user-added" element={<AssignedShareBayModalUserAdded />} />
          <Route path="/assigned-share-bay-modal-edit-share" element={<AssignedShareBayModalEditShare />} />
          <Route path="/assigned-bay-shared" element={<AssignedBayShared />} />
          <Route path="/on-demand-landing" element={<OnDemandLanding />} />
          <Route path="/on-demand-transactions" element={<OnDemandTransactions />} />
          <Route path="/on-demand-transactions-expanded" element={<OnDemandTransactionsExpanded />} />
          <Route path="/on-demand-transactions-filter-list" element={<OnDemandTransactionsFilterList />} />
          <Route path="/on-demand-transactions-custom-date-range" element={<OnDemandTransactionsCustomDateRange />} />
          <Route
            path="/on-demand-transactions-custom-date-range-picker"
            element={<OnDemandTransactionsCustomDateRangePicker />}
          />
          <Route path="/on-demand-transactions-list-searching" element={<OnDemandTransactionsListSearching />} />
          <Route path="/on-demand-transaction-detail" element={<OnDemandTransactionDetail />} />
          <Route path="/on-demand-transaction-detail-completed" element={<OnDemandTransactionDetailCompleted />} />
          <Route path="/on-demand-transaction-detail-window-closed" element={<OnDemandTransactionDetailWindowClosed />} />
          <Route path="/on-demand-transaction-detail-refund-modal" element={<OnDemandTransactionDetailRefundModal />} />
          <Route path="/on-demand-transaction-detail-refund-success" element={<OnDemandTransactionDetailRefundSuccess />} />
          <Route path="/on-demand-transaction-detail-refund-error" element={<OnDemandTransactionDetailRefundError />} />
          <Route path="/on-demand-transaction-detail-cancel-modal-a" element={<OnDemandTransactionDetailCancelModalA />} />
          <Route path="/on-demand-transaction-detail-cancel-modal-b" element={<OnDemandTransactionDetailCancelModalB />} />
          <Route path="/on-demand-transaction-cancelled" element={<OnDemandTransactionCancelled />} />
          <Route path="/on-demand-booking-who-for" element={<OnDemandBookingWhoFor />} />
          <Route path="/on-demand-booking-who-for-no-guest-available" element={<OnDemandBookingWhoForNoGuestAvailable />} />
          <Route path="/on-demand-booking-where-when" element={<OnDemandBookingWhereWhen />} />
          <Route path="/on-demand-booking-choose-product" element={<OnDemandBookingChooseProduct />} />
          <Route path="/on-demand-booking-admin" element={<OnDemandBookingAdmin />} />
          <Route path="/on-demand-booking-no-price" element={<OnDemandBookingNoPrice />} />
          <Route path="/on-demand-booking-no-availability" element={<OnDemandBookingNoAvailability />} />
          <Route path="/on-demand-booking-clash" element={<OnDemandBookingClash />} />
          <Route path="/on-demand-booking-confirmation" element={<OnDemandBookingConfirmation />} />
          <Route path="/on-demand-change-booking-where-when" element={<OnDemandChangeBookingWhereWhen />} />
          <Route path="/on-demand-change-booking-choose-product" element={<OnDemandChangeBookingChooseProduct />} />
          <Route path="/on-demand-change-booking-no-cost-change" element={<OnDemandChangeBookingNoCostChange />} />
          <Route path="/on-demand-change-booking-no-additional-charge" element={<OnDemandChangeBookingNoAdditionalCharge />} />
          <Route path="/on-demand-change-booking-gap-refund" element={<OnDemandChangeBookingGapRefund />} />
          <Route path="/on-demand-change-booking-updated-transaction" element={<OnDemandChangeBookingUpdatedTransaction />} />
          <Route path="/on-demand-products-capacities" element={<OnDemandProductsCapacities />} />
          <Route path="/on-demand-block-out-dates" element={<OnDemandBlockOutDates />} />
          <Route path="/on-demand-block-out-dates-modal" element={<OnDemandBlockOutDatesModal />} />
          <Route
            path="/on-demand-block-out-dates-modal-date-select"
            element={<OnDemandBlockOutDatesModalDateSelect />}
          />
          <Route
            path="/on-demand-block-out-dates-modal-confirmation"
            element={<OnDemandBlockOutDatesModalConfirmation />}
          />
          <Route
            path="/on-demand-block-out-dates-modal-single-dept"
            element={<OnDemandBlockOutDatesModalSingleDept />}
          />
          <Route path="/on-demand-block-out-dates-modal-multi-dept" element={<OnDemandBlockOutDatesModalMultiDept />} />
          <Route
            path="/on-demand-block-out-dates-modal-multi-dept-all-selected"
            element={<OnDemandBlockOutDatesModalMultiDeptAllSelected />}
          />
          <Route
            path="/on-demand-block-out-dates-modal-multi-dept-specific-selected"
            element={<OnDemandBlockOutDatesModalMultiDeptSpecificSelected />}
          />
          <Route
            path="/on-demand-block-out-dates-modal-multi-dept-one-selected"
            element={<OnDemandBlockOutDatesModalMultiDeptOneSelected />}
          />
          <Route path="/on-demand-single-product-site" element={<OnDemandSingleProductSite />} />
          <Route path="/on-demand-multi-product-site" element={<OnDemandMultiProductSite />} />
          <Route path="/on-demand-multi-site-multi-dept" element={<OnDemandMultiSiteMultiDept />} />
          <Route path="/guest-landing" element={<GuestLanding />} />
          <Route path="/guest-choose-date" element={<GuestChooseDate />} />
          <Route path="/guest-choose-bay-reserved" element={<GuestChooseBayReserved />} />
          <Route path="/guest-choose-bay-no-avails" element={<GuestChooseBayNoAvails />} />
          <Route path="/guest-choose-bay-unreserved" element={<GuestChooseBayUnreserved />} />
          <Route path="/guest-choose-bay-unreserved-no-avails" element={<GuestChooseBayUnreservedNoAvails />} />
          <Route path="/guest-availability-calendar-modal" element={<GuestAvailabilityCalendarModal />} />
          <Route path="/utilisation-full-month" element={<UtilisationFullMonth />} />
          <Route path="/utilisation-partial-month" element={<UtilisationPartialMonth />} />
          <Route path="/utilisation-multi-team" element={<UtilisationMultiTeam />} />
          <Route path="/utilisation-multi-team-team-select" element={<UtilisationMultiTeamTeamSelect />} />
          <Route path="/utilisation-filtered-by-team" element={<UtilisationFilteredByTeam />} />
          <Route path="/settings-landing" element={<SettingsLanding />} />
          <Route path="/settings-manage-users" element={<SettingsManageUsers />} />
          <Route path="/settings-manage-users-filter-dropdown" element={<SettingsManageUsersFilterDropdown />} />
          <Route path="/settings-manage-user-assigned" element={<SettingsManageUserAssigned />} />
          <Route path="/settings-manage-user-recent-bookings" element={<SettingsManageUserRecentBookings />} />
          <Route path="/settings-self-registration" element={<SettingsSelfRegistration />} />
          <Route path="/settings-self-registration-disabled" element={<SettingsSelfRegistrationDisabled />} />
          <Route path="/settings-add-domain" element={<SettingsAddDomain />} />
          <Route path="/settings-add-user-modal-blank" element={<SettingsAddUserModalBlank />} />
          <Route path="/settings-add-user-modal-complete" element={<SettingsAddUserModalComplete />} />
          <Route path="/settings-bulk-add-users-modal-blank" element={<SettingsBulkAddUsersModalBlank />} />
          <Route path="/settings-bulk-add-users-modal-upload" element={<SettingsBulkAddUsersModalUpload />} />
          <Route path="/settings-bulk-add-users-modal-blank-error" element={<SettingsBulkAddUsersModalBlankError />} />
          <Route path="/self-registration-multi-dept-modal-complete" element={<SelfRegistrationMultiDeptModalComplete />} />
          <Route path="/self-registration-multi-dept-modal-error" element={<SelfRegistrationMultiDeptModalError />} />
          <Route path="/self-registration-single-dept-modal-complete" element={<SelfRegistrationSingleDeptModalComplete />} />
          <Route path="/self-registration-single-dept-modal-error" element={<SelfRegistrationSingleDeptModalError />} />
          <Route path="/self-registration-modal-accepted-pending-verification" element={<SelfRegistrationModalAcceptedPendingVerification />} />
          <Route path="/self-registration-modal-not-available" element={<SelfRegistrationModalNotAvailable />} />
          <Route path="/self-registration-modal-admin-review" element={<SelfRegistrationModalAdminReview />} />
          <Route path="/self-registration-modal-accepted" element={<SelfRegistrationModalAccepted />} />
          <Route path="/control-centre-search" element={<ControlCentreSearch />} />
          <Route path="/control-centre-no-results" element={<ControlCentreNoResults />} />
          <Route path="/control-centre-landing" element={<ControlCentreLanding />} />
          <Route path="/control-centre-on-demand-booking" element={<ControlCentreOnDemandBooking />} />
          <Route path="/control-centre-guest-booking" element={<ControlCentreGuestBooking />} />
          <Route path="/control-centre-assigned-booking" element={<ControlCentreAssignedBooking />} />
          <Route path="/control-centre-change-entry-status-modal-inside" element={<ControlCentreChangeEntryStatusModalInside />} />
          <Route path="/control-centre-change-entry-status-modal-nested-inside" element={<ControlCentreChangeEntryStatusModalNestedInside />} />
          <Route path="/control-centre-change-entry-status-modal-nested-outside" element={<ControlCentreChangeEntryStatusModalNestedOutside />} />
          <Route path="/control-centre-change-entry-status-modal-nested-transiting-in" element={<ControlCentreChangeEntryStatusModalNestedTransitingIn />} />
          <Route path="/control-centre-change-entry-status-modal-nested-transiting-out" element={<ControlCentreChangeEntryStatusModalNestedTransitingOut />} />
          <Route path="/control-centre-change-entry-status-modal-nested-outside-2" element={<ControlCentreChangeEntryStatusModalNestedOutside2 />} />
          <Route path="/control-centre-status-change-success" element={<ControlCentreStatusChangeSuccess />} />
          <Route path="/control-centre-status-change-error" element={<ControlCentreStatusChangeError />} />
          <Route path="/control-centre-open-gate-modal" element={<ControlCentreOpenGateModal />} />
          <Route path="/control-centre-open-gate-modal-selected" element={<ControlCentreOpenGateModalSelected />} />
          <Route path="/control-centre-open-gate-success" element={<ControlCentreOpenGateSuccess />} />
          <Route path="/control-centre-open-gate-error" element={<ControlCentreOpenGateError />} />
          <Route path="/control-centre-force-gate-open" element={<ControlCentreForceGateOpen />} />
          <Route path="/control-centre-force-gate-open-opened" element={<ControlCentreForceGateOpenOpened />} />
          <Route path="/control-centre-force-gate-open-error" element={<ControlCentreForceGateOpenError />} />
          <Route path="/navigation-mobile" element={<NavigationMobile />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  )
}

export default RoutesUtil
