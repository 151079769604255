import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import wilsonLogo from '../assets/svg/Wilson-Parking-Logo.svg';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import notVisibleIcon from '../assets/svg/ws-ui-icon-not-visible-gry.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import adminReviewIcon from '../assets/svg/ws-b2b-icon-weekend.svg'


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		padding: '7px 22px',
	},
	selfRegModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '185px',
		width: '705px',
		textAlign: 'left',
		boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
	},
}));

export default function CenteredGrid() {
	  const classes = useStyles();
	return <>
		<Container className="selfRegBG">

			<div id="header-wrapper" className="login-header">

				<div id="header" className="main-nav self-reg-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
				</div>

			</div>

			<Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
				<Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} className="justified-content">
							<img className="self-reg-icon self-reg-icon-admin-review" src={adminReviewIcon} alt="Review" />
							<h2 className="self-reg-h2 centered-h2">Thanks, we’ll get back to you.</h2>
							<p className="self-reg-sub-text">Your application has been sent over to your company administrator to review. Once approved, we’ll send you an email with a link to verify your email address to download the Shuffle Parking App.</p>
						</Grid>
						<Grid item xs={12}>
							<p className="self-reg-email-text">Keep an eye on your spam or junk folders for the approval email.<br />Please contact your company administrator on <strong><a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a></strong> if you need help.</p>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<div id="footer" className="login-page dark-bg">

				<div id="footer-text">
					<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				</div>

			</div>

		</Container>



	</>
}

