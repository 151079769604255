import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UtilisationHeader from "../components/utilisation-header";
import summaryGraphImg from '../assets/images/utilisation-graph-placeholder.png';
import summaryGraphPartialImg from '../assets/images/utilisation-graph-placeholder-2.png';
import ondemandPieChartImg from '../assets/images/utilisation-pie-chart-placeholder.png';
import usersBarChartImg from '../assets/images/utilisation-bar-chart-placeholder.png';
import downloadIcon from '../assets/svg/ws-b2b-icon-report_bar_chart-2.svg';
import calendarIcon from '../assets/svg/ws-ui-icon-calendar-grey.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '200px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
		paddingTop: '7px',
		paddingBottom: '7px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<UtilisationHeader />
			<div className="page-content-wrapper">

					<Container className="page-content-header-wrapper utilisation-section">
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12} className="full-width top-section">
								<h1 className="utilisation-h1">Utilisation</h1>
								
								<Grid container spacing={2}>
									
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},
									
											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">George Street Office</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
									
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Month</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},
									
											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Past 30 days</MenuItem>
											<MenuItem value="20">September 2021</MenuItem>
											<MenuItem value="30">October 2021</MenuItem>
											<MenuItem value="40">November 2021</MenuItem>
											<MenuItem value="50">December 2021</MenuItem>
											<MenuItem value="60">January 2022</MenuItem>
										 </Select>
									</Grid>
									
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Teams</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Any department/team</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
									
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">All roles</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">All roles</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">LOption 2</MenuItem>
										 </Select>
									</Grid>

								</Grid>
							</Grid>
						</Grid>
					</Container>


				<Container className="page-content-body-wrapper utilisation-section">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} className="full-width bottom-section">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<h3>Summary</h3>
										<p className="utilisation-period">Past 30 days</p>
									</Grid>
									<Grid item xs={4}>
										<p className="summary-utilisation-key"><span className="key-icon assigned-utilisation-icon"></span>Assigned <span className="key-icon ondemand-utilisation-icon"></span>On-demand <span className="key-icon guest-utilisation-icon"></span>Guest</p>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '50px' }} >
									<Grid item xs={2.16}>
										<h4 className="summary-data-h4 highlighted-h4">346</h4>
										<p className="summary-data-text">Total bays</p>
									</Grid>
									<Grid item xs={2.4}>
										<h4 className="summary-data-h4">86%</h4>
										<p className="summary-data-text">Overall bay utilisation</p>
									</Grid>
									<Grid item xs={3.12}>
										<h4 className="summary-data-h4">Tues 9 June</h4>
										<p className="summary-data-text">Highest utilisation day</p>
									</Grid>
									<Grid item xs={2.66}>
										<h4 className="summary-data-h4">$7,200.75</h4>
										<p className="summary-data-text">Realised revenue</p>
									</Grid>
									<Grid item xs={1.66}>
										<h4 className="summary-data-h4">$2,025.00</h4>
										<p className="summary-data-text">Goodwill revenue</p>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '35px' }}>
									<Grid item xs={12}>
										<img className="summary-graph-placeholder" src={summaryGraphPartialImg} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3>On-demand parking</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '32px' }} >
									<Grid item xs={4}>
										<img className="ondemand-piechart-placeholder" src={ondemandPieChartImg} />
									</Grid>
									<Grid item xs={8}>
										<table id="utilisation-summary" className="ondemand-data">
											<tr>
												<th>Category</th>
												<th>Utilisation</th>
											</tr>
											<tr>
												<td><span className="daily-parking-key"></span><strong>Assignments</strong></td>
												<td>1,030 (77%)</td>
											</tr>
											<tr>
												<td><span className="hourly-parking-key"></span><strong>Guest bookings</strong></td>
												<td>300 (23%)</td>
											</tr>
										</table>
									</Grid>
								</Grid>
							</Grid>
							
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="utilisation-section light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

