import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeHeaderMobile from "../components/home-header-mobile";
import dashboardGraphImg from '../assets/svg/dashboard-bar-chart-placeholder.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';
import assignIcon from '../assets/svg/ws-b2b-icon-reserved-2.svg';
import mobileIcon from '../assets/svg/ws-b2b-icon-mobile-2.svg';
import usersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import operationsIcon from '../assets/svg/ws-b2b-icon-operations.svg';
import loadingIcon from '../assets/svg/ws-ui-icon-loader-red.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
		paddingTop: '7px',
		paddingBottom: '7px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG mobileNav">
			<HomeHeaderMobile />
				
			<div className="modal-mobile-wrapper mobile-only">
				<Box className="mobile-menu-modal">
					<div className="nav-holder-mobile row">
						<ul id="shuffle-main-nav-mobile">
							<li className="selected"><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children"><a href="#" className="main-nav-item">Operations</a>
								<a href="#" className="child-nav-item"><img className="mobile-menu-li-icon operations-icon" src={operationsIcon} />Control centre</a>
							</li>
						</ul>
					</div>
					<div className="mobile-menu-footer">
						<div className="user-mobile">
							<a id="user-loggedin" href="#">WP</a>
								<p className="user-details">
									<a href="#"><strong>Paul Sidwell</strong><br />
									<span className="user-email">paul@company.com.au</span></a></p>
							
						</div>
						<ul className="user-logout">
							<li><a href="#">Logout</a></li>
						</ul>
					</div>
				</Box>
			</div>
			
			<div className="page-content-wrapper dashboard-section">

					<Container className="page-content-header-wrapper dashboard-section">
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12} className="full-width top-section">
								<h1 className="dashboard-h1">Morning Paul</h1>
							</Grid>
						</Grid>
					</Container>


				<Container className="page-content-body-wrapper dashboard-section">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} className="full-width bottom-section">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2} style={{ paddingBottom: '32px' }}>

									<Grid item xs={6} style={{ paddingRight: '0px' }}>
										<h2>Your parking</h2>
									</Grid>

									<Grid item xs={6} style={{ paddingLeft: '64px' }}>
										<InputLabel className="select-heading">Location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											 MenuProps={{
											   anchorOrigin: {
												 vertical: "bottom",
												 horizontal: "left"
											   },
											   transformOrigin: {
												 vertical: "top",
												 horizontal: "left"
											   },

											 }}
										 value="10" variant="outlined" margin="dense" fullWidth>
											 <MenuItem value="10" className="selectBold">George Street Office</MenuItem>
											 <MenuItem value="20">Option 1</MenuItem>
											 <MenuItem value="30">Option 2</MenuItem>
										  </Select>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} className="dash-loading-panel">
										<img className="dash-loading-icon" src={loadingIcon} alt="Loading" />
									</Grid>
								</Grid>
							</Grid>

							<Grid container spacing={2} style={{ display: 'flex', paddingBottom: '20px' }}>
								<Grid item xs={2.4} className="eq-height-col desktop-only">
									<Box className="dash-section-card">
										<div className="dash-icon-wrapper">
											<img className="dash-section-icon dash-assign" src={assignIcon} alt="Assigned parking" />
										</div>
										<h3>Assigned parking</h3>
										<p className="dash-card-text">Assign parking bays to a single user or share a bay between multiple users.</p>
										<ul className="dash-card-links">
											<li><a href="" className="link-text">Manage parkers</a></li>
											<li><a href="" className="link-text">Assign parking</a></li>
											<li><a href="" className="link-text">Manage bay</a></li>
										</ul>
									</Box>
								</Grid>
							
								<Grid item xs={2.4} className="eq-height-col desktop-only">
									<Box className="dash-section-card">
										<div className="dash-icon-wrapper">
											<img className="dash-section-icon dash-on-demand" src={mobileIcon} alt="On-demand parking" />
										</div>
										<h3>On-demand parking</h3>
										<p className="dash-card-text">Bays for your staff to book on-demand.</p>
										<ul className="dash-card-links">
											<li><a href="" className="link-text">Transactions</a></li>
											<li><a href="" className="link-text">Book parking</a></li>
											<li><a href="" className="link-text">Products and capacities</a></li>
											<li><a href="" className="link-text">Block out dates</a></li>
										</ul>
									</Box>
								</Grid>
							
								<Grid item xs={2.4} className="eq-height-col desktop-only">
									<Box className="dash-section-card">
										<div className="dash-icon-wrapper">
											<img className="dash-section-icon dash-book-guest" src={guestIcon} alt="Guest parking" />
										</div>
										<h3>Guest parking</h3>
										<p className="dash-card-text">Book parking for a guest, visitor or contractor.</p>
										<ul className="dash-card-links">
											<li><a href="" className="link-text">Book for a guest</a></li>
											<li><a href="" className="link-text">Bookings</a></li>
										</ul>
									</Box>
								</Grid>
								
								<Grid item xs={2.4} className="eq-height-col desktop-only">
									<Box className="dash-section-card">
										<div className="dash-icon-wrapper"> 
											<img className="dash-section-icon dash-users" src={usersIcon} alt="Users" />
										</div>
										<h3>Users</h3>
										<p className="dash-card-text">Manage users and bulk user uploads.</p>
										<ul className="dash-card-links">
											<li><a href="" className="link-text">Manage users</a></li>
											<li><a href="" className="link-text">Bulk registrations</a></li>
										</ul>
									</Box>
								</Grid>
								
								<Grid item xs={2.4} className="eq-height-col">
									<Box className="dash-section-card">
										<div className="dash-icon-wrapper">
											<img className="dash-section-icon dash-operations" src={operationsIcon} alt="Operations" />
										</div>
										<h3>Operations</h3>
										<p className="dash-card-text">Help your parkers get in and out of the car park.</p>
										<ul className="dash-card-links">
											<li><a href="" className="link-text">Control Centre</a></li>
										</ul>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="dashboard-section light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

