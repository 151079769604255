import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import OnDemandHeader from "../components/on-demand-header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import dateIcon from '../assets/svg/ws-b2b-icon-date.svg';
import calendarIcon from '../assets/svg/ws-ui-icon-calendar-2.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import clearFieldIcon from '../assets/svg/ws-ui-icon-close-x-grey.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import productIconCar from '../assets/svg/product-icon-car.svg';
import productIconSun from '../assets/svg/product-icon-sun.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0px',
		maxWidth: '200px',
	},
	shuffleButtonSml: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '0.875rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
		maxWidth: '120px',
		paddingBottom: '7px',
		boxShadow: 'none',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OnDemandHeader />
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2 no-padding"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Change booking</a></h2>
								</Grid>
							</Grid>
							
							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p style={{ margin: "10px 0 10px 0" }}>Transaction ID: 123456789</p>
									<p style={{ margin: "0" }}>Sam McMillan</p>
									<p className="notifcation-text">25 August 2021 6:00am - 25 August 2021 7:00pm</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0} className="guest-booking-list-item">
									<Grid item xs={4}>
										<div className="on-demand-icon-block">
											<img className="guest-icon date-icon" src={dateIcon} />
											<h4 className="list-item-header">Where and when?</h4>
										</div>
									</Grid>
									
									<Grid item xs={8}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<InputLabel className="select-heading">Location</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													MenuProps={{
											  		anchorOrigin: {
														vertical: "bottom",
														horizontal: "left"
											  		},
											  		transformOrigin: {
														vertical: "top",
														horizontal: "left"
											  		},
												
													}}
												value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">George Street Office</MenuItem>
													<MenuItem value="20">Option 1</MenuItem>
													<MenuItem value="30">Option 2</MenuItem>
												</Select>
											</Grid>
											<Grid item xs={3.7}>
												<p className="input-label">Arrive</p>
												<TextField id="outlined-basic" defaultValue="4 August 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
												InputProps={{
												className: "shuffleInputIcon",
												  endAdornment: (
													<InputAdornment position="end">
													  <img className="input-calendar-icon" src={calendarIcon} />
													</InputAdornment>
												   )
												  }} />
											</Grid>
											<Grid item xs={2.3}>
												<InputLabel className="select-heading">&nbsp;</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													MenuProps={{
													  anchorOrigin: {
														vertical: "bottom",
														horizontal: "left"
													  },
													  transformOrigin: {
														vertical: "top",
														horizontal: "left"
													  },
												
													}}
												value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">9:00am</MenuItem>
													<MenuItem value="20">10:00am</MenuItem>
													<MenuItem value="30">11:00am</MenuItem>
												</Select>
											</Grid>
											<Grid item xs={3.7}>
												<p className="input-label">Depart</p>
												<TextField id="outlined-basic" defaultValue="4 August 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
												InputProps={{
												className: "shuffleInputIcon",
												  endAdornment: (
													<InputAdornment position="end">
													  <img className="input-calendar-icon" src={calendarIcon} />
													</InputAdornment>
												   )
												  }} />
											</Grid>
											<Grid item xs={2.3}>
												<InputLabel className="select-heading">&nbsp;</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													MenuProps={{
													  anchorOrigin: {
														vertical: "bottom",
														horizontal: "left"
													  },
													  transformOrigin: {
														vertical: "top",
														horizontal: "left"
													  },
												
													}}
												value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">9:00pm</MenuItem>
													<MenuItem value="20">10:00pm</MenuItem>
													<MenuItem value="30">11:00pm</MenuItem>
												</Select>
											</Grid>
											<Grid item xs={12} style={{ paddingTop: '15px' }}>
												<Button className={classes.shuffleButtonSml} variant="contained" color="primary" size="small">Search</Button>
											</Grid>
											
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={0} className="guest-booking-list-item">
									<Grid item xs={4}>
										<div className="on-demand-icon-block">
											<img className="guest-icon date-icon" src={prodsCapacitiesIcon} />
											<h4 className="list-item-header">Choose a <br />parking product</h4>
										</div>
									</Grid>
									
									<Grid item xs={8}>
										<Grid container spacing={2}>
											<Grid item xs={12} className="parking-products">
												<div className="parking-product-wrapper selected">
													<div className="product-icon-wrapper">
														<img className="product-icon car-icon" src={productIconCar} />
													</div>
													<h4 className="parking-product-title">Staff parking</h4>
													<p className="parking-product-price">$16.50</p>
												</div>
											</Grid>
											<Grid item xs={12}>
												<h4 className="product-desc-title">Things to know about Staff Parking</h4>
													
												<p className="product-desc-body">This is the product description for the selected product. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								
								<Grid container spacing={0}>
									<Grid item xs={4}>
									</Grid>
									<Grid item xs={8}>
										<h4 className="product-desc-title">This booking was paid by Sam McMillan</h4>
											
										<p className="product-desc-body">The decreased booking cost (-$8.00) <strong className="green-text">will be refunded to</strong> Sam McMillan on their original payment method.</p>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '20px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-start">
											<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large" >Cancel</Button>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Change</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="assigned-section light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

