import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import reservedIcon from '../assets/svg/ws-b2b-icon-reserved-2.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0px',
		maxWidth: '200px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2 no-padding"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Assign a bay</a></h2>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0}>
									<Grid item xs={4}>
										<div className="assigned-icon-block">
											<img className="assigned-icon reserved-icon" src={reservedIcon} />
											<h4 className="">Choose who will use this bay</h4>
											<p>Add one or more users </p>
										</div>
									</Grid>
									<Grid item xs={8}>
										<TextField id="outlined-basic" defaultValue="Search by name or email address" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										InputProps={{
											className: "shuffleInput",
											  startAdornment: (
												<InputAdornment position="start">
												  <img className="search-field-icon" src={searchFieldIcon} />
												</InputAdornment>
											   )
											  }} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '20px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-start">
											<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large" >Cancel</Button>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" disabled>Next</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="assigned-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

