import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import addIcon from '../assets/svg/ws-ui-icon-add-white.svg';
import closeIcon from '../assets/svg/ws-ui-icon-close-x-grey.svg';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import tickIcon from '../assets/svg/ws-sml-icon-tick-wht-50.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
	padding: 0,
	margin: 4,
	transitionDuration: '300ms',
	'&.Mui-checked': {
	  transform: 'translateX(32px)',
	  color: '#fff',
	  '& + .MuiSwitch-track': {
		backgroundColor: '#009273',
		backgroundImage: `url('${tickIcon}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '12px center',
		backgroundSize: '23%',
		opacity: 1,
		border: 0,
	  },
	  '&.Mui-disabled + .MuiSwitch-track': {
		opacity: 0.5,
	  },
	},
	'&.Mui-focusVisible .MuiSwitch-thumb': {
	  color: '#33cf4d',
	  border: '6px solid #fff',
	},
	'&.Mui-disabled .MuiSwitch-thumb': {
	  color: 'black',
	},
	'&.Mui-disabled + .MuiSwitch-track': {
	  opacity: 0.5,
	},
  },
  '& .MuiSwitch-thumb': {
	boxSizing: 'border-box',
	width: 24,
	height: 24,
  },
  '& .MuiSwitch-track': {
	borderRadius: 32 / 2,
	backgroundColor: '#BBBABA',
	opacity: 1,
	transition: theme.transitions.create(['background-color'], {
	  duration: 500,
	}),
  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Self registration link</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper full-width">
								<Grid item xs={12}>
									<p>You can allow your staff to register for on-demand parking via a link on your intranet or other company channels.</p>
									<p>Choose to manually review users, or automatically approve them based on verified email addresses.</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper settings-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0}>
									<Grid item xs={10}>
										<h3 className="padding-bottom-20">Enable self registration</h3>
									</Grid>
									<Grid item xs={2}>
										<FormGroup>
										  <FormControlLabel className="custom-switch"
											control={<IOSSwitch defaultChecked />}
											label="Enabled"
											labelPlacement="start"
										  />
										</FormGroup>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<p className="select-heading">Your shared link</p>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={10}>
										<TextField inputProps={{ className: "shuffleInput" }} id="outlined-basic" defaultValue="https://apex.wilsonparking.com.au/invite/12758390037490ab172329jsa" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
									</Grid>
									<Grid item xs={2}>
										<a href="#" className="copy-link">Copy Link</a>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<h3 className="padding-bottom-10">Approved domains</h3>
										<p>Add self registration email domains.</p>
									</Grid>
									<Grid item xs={4} style={{ textAlign: "right" }}>
										<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" startIcon={<img className="buttonIcon" src={addIcon} />} style={{ maxWidth: "152px"}} >Add domain</Button>
									</Grid>
								</Grid>
								<Grid container spacing={2} className={classes.gridTopMargin}>
									<Grid item xs={12}>
										<div className="location-card">
											<p className="domain-title">Australian domain</p>
											<p className="location-product-count"><span className="domain-url">@company.com.au</span> <span className="icon-approval">Automatic approval</span></p>
											<a href="#" className="close-card"><img className="close-x" src={closeIcon} /></a>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className="location-card">
											<p className="domain-title">US domain</p>
											<p className="location-product-count"><span className="domain-url">@company.com</span> <span className="icon-approval">Manual administrator approval</span></p>
											<a href="#" className="close-card"><img className="close-x" src={closeIcon} /></a>
										</div>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={2} className={classes.gridTopMargin}>
									<Grid item xs={12} style={{ textAlign: "right" }}>
										<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" style={{ maxWidth: "200px"}} >Save</Button>
									</Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="settings-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

