import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import earlyBirdIcon from '../assets/svg/ws-b2b-icon-earlybird.svg';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import tickIcon from '../assets/svg/ws-sml-icon-tick-wht-50.svg';
import closeIcon from '../assets/svg/ws-ui-icon-close-x-grey.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '200px',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
	padding: 0,
	margin: 4,
	transitionDuration: '300ms',
	'&.Mui-checked': {
	  transform: 'translateX(32px)',
	  color: '#fff',
	  '& + .MuiSwitch-track': {
		backgroundColor: '#009273',
		backgroundImage: `url('${tickIcon}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '12px center',
		backgroundSize: '23%',
		opacity: 1,
		border: 0,
	  },
	  '&.Mui-disabled + .MuiSwitch-track': {
		opacity: 0.5,
	  },
	},
	'&.Mui-focusVisible .MuiSwitch-thumb': {
	  color: '#33cf4d',
	  border: '6px solid #fff',
	},
	'&.Mui-disabled .MuiSwitch-thumb': {
	  color: 'black',
	},
	'&.Mui-disabled + .MuiSwitch-track': {
	  opacity: 0.5,
	},
  },
  '& .MuiSwitch-thumb': {
	boxSizing: 'border-box',
	width: 24,
	height: 24,
  },
  '& .MuiSwitch-track': {
	borderRadius: 32 / 2,
	backgroundColor: '#BBBABA',
	backgroundImage: `url('${closeIcon}')`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '75% center',
	backgroundSize: '23%',
	opacity: 1,
	transition: theme.transitions.create(['background-color'], {
	  duration: 500,
	}),
  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="selected  has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />George Street Office</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper full-wdith">
								<Grid item xs={12}>
									<p>Set capacity limits at this car park <br /> based on teams.</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12} className="show-hide-wrapper expanded">
										<img className="parking-icon earlybird-icon" src={earlyBirdIcon} />
										<h3>Free daily parking</h3>
										<a href="" className="hide-details">Hide details</a>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<InputLabel className="select-heading">Total capacity limit for product</InputLabel>
										<Select value="200bays" label="200 bays" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="200bays">200 bays</MenuItem>
											<MenuItem value="300bays">300 bays</MenuItem>
											<MenuItem value="400bays">400 bays</MenuItem>
											<MenuItem value="500bays">500 bays</MenuItem>
										 </Select>
									</Grid>
									<Grid item xs={6}>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} className="enable-options single-dept ">
										<a href="" className="enable-all">Enable All</a>
										<a href="" className="enable-all">Disable All</a>
										<p className="optional-limit">Optional limit per team</p>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Box className="switch-card">
											<Grid container spacing={2}>
												<Grid item xs={10}>
													<FormGroup>
													  <FormControlLabel className="parking-switch"
														control={<IOSSwitch defaultChecked />}
														label="Business mobile sales team"
														labelPlacement="end"
													  />
													</FormGroup>
												</Grid>
												<Grid item xs={2} className="flex-aligned">
													<a href="" className="set-bays">12 bays</a>
												</Grid>
											</Grid>
										</Box>
										<Box className="switch-card">
											<Grid container spacing={2}>
												<Grid item xs={10}>
													<FormGroup>
													  <FormControlLabel className="parking-switch"
														control={<IOSSwitch defaultChecked />}
														label="Office staff"
														labelPlacement="end"
													  />
													</FormGroup>
												</Grid>
												<Grid item xs={2} className="flex-aligned">
													<a href="" className="set-bays">10 bays</a>
												</Grid>
											</Grid>
										</Box>
										<Box className="switch-card">
											<Grid container spacing={2}>
												<Grid item xs={10}>
													<FormGroup>
													  <FormControlLabel className="parking-switch"
														control={<IOSSwitch defaultChecked />}
														label="Operations team"
														labelPlacement="end"
													  />
													</FormGroup>
												</Grid>
												<Grid item xs={2} className="flex-aligned">
													<a href="" className="set-bays">No limit</a>
												</Grid>
											</Grid>
										</Box>
										<Box className="switch-card">
											<Grid container spacing={2}>
												<Grid item xs={10}>
													<FormGroup>
													  <FormControlLabel className="parking-switch"
														control={<IOSSwitch />}
														label="Retail mobile sales team"
														labelPlacement="end"
													  />
													</FormGroup>
												</Grid>
												<Grid item xs={2}>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '5px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-start">
											<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large" >Cancel</Button>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Save changes</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="ondemand-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

