import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import editIcon from '../assets/svg/ws-ui-icon-edit-white.svg';
import usageCalendar from '../assets/images/usage-calendar-placeholder.png';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Sam McMillan</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<div className="responsive-table-wrapper">
										<table className="assigned-parking-table table-section-one">
											<tr className="table-section">
												<td className="table-cell-left">Type</td>
												<td className="table-cell-right"><strong>Reserved parking</strong></td>
											</tr>
											<tr>
												<td>Location</td>
												<td><strong>George Street Car Park</strong></td>
											</tr>
										</table>
										<table className="assigned-parking-table table-section-two">
											<tr className="table-section">
												<td className="table-cell-left">Level</td>
												<td className="table-cell-right"><strong>Level 1</strong></td>
											</tr>
											<tr>
												<td>Zone</td>
												<td><strong>Zone C</strong></td>
											</tr>
										</table>
										<table className="assigned-parking-table table-section-three">
											<tr className="table-section">
												<td className="table-cell-left">Bay</td>
												<td className="table-cell-right"><strong>76</strong></td>
											</tr>
										</table>
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper">
								<Grid item xs={6}>
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" startIcon={<img className="editIcon" src={editIcon} />} >Edit</Button>
								</Grid>
								<Grid item xs={6}>
									<Button className="outlined-mono" variant="outlined" color="secondary" size="large" style={{ width: '140px' }}>Unassign</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-20">Bay use</h3>
									</Grid>
								</Grid>
								<Grid container spacing={0}>
									<Grid item xs={5}>
										<table id="bay-use-table">
											<tr>
												<td>Starts</td>
												<td><strong>7 October 2021</strong></td>
											</tr>
											<tr>
												<td>Expires</td>
												<td><strong>Never</strong></td>
											</tr>
										</table>
									</Grid>
									<Grid item xs={7}>
										<div className="day-usage-wrapper">
											<ul className="day-usage-menu">
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">M</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">T</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon enabled">W</a>
													<p className="day-usage-text">All day</p>
												</li>
												<li className="day-usage-item"><a href="" className="day-usage-icon disabled">T</a></li>
												<li className="day-usage-item"><a href="" className="day-usage-icon disabled">F</a></li>
												<li className="day-usage-item"><a href="" className="day-usage-icon disabled">S</a></li>
												<li className="day-usage-item"><a href="" className="day-usage-icon disabled">S</a></li>
											</ul>
										</div>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={10}>
										<h3 className="padding-bottom-10">Parking utilisation</h3>
									</Grid>
									<Grid item xs={2}>
										<p className="heading-subtitle">Past 30 days</p>
									</Grid>
								</Grid>
								<Grid container spacing={2} alignItems="center" style={{ paddingTop: '20px' }}>
									<Grid item xs={2}>
										<div className="percentage-utilised-graphic">
											<h6 className="percentage-utilised-header">95%</h6>
											<p className="percentage-utilised-text">Utilised</p>
										</div>
									</Grid>
									<Grid item xs={3}>
										<h3 className="percentage-used">75%</h3>
										<p className="percentage-used-by">Used by Sam McMillan</p>
									</Grid>
									<Grid item xs={2}>
										<h3 className="percentage-remaining">20%</h3>
										<p className="percentage-used-by">Not used</p>
									</Grid>
									<Grid item xs={5}>
										<h3 className="percentage-shared">5%</h3>
										<p className="percentage-used-by">Shared with other users</p>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={5}>
										<h4 className="content-panel-h4">Bay sharing</h4>
										<p className="descriptive-text">You can temporarily share this bay on behalf of its user when they don’t need it.</p>
										<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" style={{ width: '160px' }}>Share bay</Button>
									</Grid>
									<Grid item xs={2}>
									</Grid>
									<Grid item xs={5}>
										<img className="calendar-placeholder" src={usageCalendar} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="assigned-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

