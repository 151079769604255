import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OnDemandHeader from "../components/on-demand-header";
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import bookParkingIcon from '../assets/svg/ws-b2b-icon-book_parking.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		textTransform: 'none',
		color: 'white',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		padding: '10px 22px',
		boxShadow: 'none',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OnDemandHeader />
			<div id="main" className="section-entry on-demand">
				<Container className="page-header">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12}>
							<h1 className="settings-h1 centered-heading">On-demand parking</h1>
							<p className="on-demand-intro">Make bays available to your staff to book on-demand.</p>
						</Grid>
					</Grid>
				</Container>
			</div>

			<Container className="page-content on-demand">
				<Grid item xs={12}>
					<Grid container justifyContent="center" spacing={1}>
						
						<Box className="section-card on-demand">
							<div className="icon-box-wrapper"><img className="on-demand-transactions" src={transactionsIcon} alt="Transactions" /></div>
							<div className="card-text-wrapper">
								<h2>Transactions</h2>
								<p>View and manage upcoming and past parking transactions.</p>
								<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">View transactions</Button>
							</div>
						</Box>
						
						<Box className="section-card on-demand">
							<div className="icon-box-wrapper"><img className="on-demand-book-parking" src={bookParkingIcon} alt="Transactions" /></div>
							<div className="card-text-wrapper">
								<h2>Book Parking</h2>
								<p>Book on-demand parking on behalf of a staff member who is registered with Shuffle.</p>
								<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Book now</Button>
							</div>
						</Box>
						
						<Box className="section-card on-demand">
							<div className="icon-box-wrapper"><img className="on-demand-prods-capacities" src={prodsCapacitiesIcon} alt="Book for a guest" /></div>
							<div className="card-text-wrapper">
								<h2>Products &amp; capacities</h2>
								<p>Manage the on-demand parking products and capacity limits.</p>
								<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Manage</Button>
							</div>
						</Box>

						<Box className="section-card on-demand">
							<div className="icon-box-wrapper"><img className="on-demand-block-dates" src={blockDatesIcon} alt="Transactions" /></div>
							<div className="card-text-wrapper">
								<h2>Block out dates</h2>
								<p>Schedule dates when on-demand parking will be unavailable.</p>
								<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Manage</Button>
							</div>
						</Box>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="lg" >
				<Grid container spacing={2} justifyContent="center" style={{ padding: "30px 0 40px 0" }}>
					<Grid item xs={12}>
						<Typography align="center" className="on-demand-sub-text">Want to see who has access to on-demand parking?</Typography>
						<Typography align="center" className="on-demand-sub-text"><a href="#" className="manage-users-link">Manage all users</a></Typography>
					</Grid>
				</Grid>
			</Container>

		</Container>

		<div id="footer" className="light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

