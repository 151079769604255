import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import OnDemandHeader from "../components/on-demand-header";
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: 'white',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		maxWidth: '145px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OnDemandHeader />
			<div className="page-content-wrapper ondemand-section">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />On-demand parking</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p style={{ marginBottom: "10px" }}>Transaction ID: 123456789<br />
									Booking status: Valid<br />
									Entry status: Off-site</p>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper right-aligned">
								<Grid item xs={12} className="inline-buttons">
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Change</Button>
									<Button className={classes.shuffleButton} variant="outlined" color="secondary" size="large">Cancel</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-10 padding-top-5">About this transaction</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail first-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>User</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>Sam McMillan</td>
													</tr>
													<tr>
														<td>sam.mcmillan@company.com.au</td>
													</tr>
													<tr>
														<td>Australia Resources Retail (Office staff)</td>
													</tr>
													<tr>
														<td>Executive</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>
												<a href="" className="transaction-link">Manage user account</a>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>Parking</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>George Street Office</td>
													</tr>
													<tr>
														<td>Staff Parking</td>
													</tr>
													<tr>
														<td>Wed 30 August 6:00am - Thu 31 August 6:00pm</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>

											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail last-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>Cost</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>$3.00</td>
													</tr>
													<tr>
														<td>Paid using VISA (ending 123)</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>
												<a href="" className="transaction-link">Refund user</a>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="position-bottom light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

