import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from '@mui/material/Button';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import summaryGraphImg from '../assets/images/utilisation-graph-placeholder.png';
import ondemandPieChartImg from '../assets/images/utilisation-pie-chart-placeholder.png';
import usersBarChartImg3 from '../assets/images/utilisation-bar-chart-placeholder-3.png';
import downloadIcon from '../assets/svg/ws-b2b-icon-report_bar_chart-2.svg';
import calendarIcon from '../assets/svg/ws-ui-icon-calendar-grey.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '200px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="selected has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>
			<div className="page-content-wrapper">

					<Container className="page-content-header-wrapper utilisation-section">
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12} className="full-width top-section">
								<Grid container spacing={0}>
									<Grid item xs={10}>
										<h1 className="utilisation-h1">Utilisation</h1>
									</Grid>
									<Grid item xs={2}>
										<Box display="flex" justifyContent="flex-end" alignItems="top">
											<a href="" className="reset-all-filters no-margin">Reset all filters</a>
										</Box>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Departments/Teams</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">All Teams</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Roles</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">All Roles</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">LOption 2</MenuItem>
										 </Select>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">George Street Office</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
									</Grid>
									<Grid item xs={3}>
										<InputLabel className="select-heading white-text">Month</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											  MenuProps={{
												anchorOrigin: {
												  vertical: "bottom",
												  horizontal: "left"
												},
												transformOrigin: {
												  vertical: "top",
												  horizontal: "left"
												},

											  }}
										  value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">August 2021</MenuItem>
											<MenuItem value="20">September 2021</MenuItem>
											<MenuItem value="30">October 2021</MenuItem>
											<MenuItem value="40">November 2021</MenuItem>
											<MenuItem value="50">December 2021</MenuItem>
											<MenuItem value="60">January 2022</MenuItem>
										 </Select>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>


				<Container className="page-content-body-wrapper utilisation-section">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} className="full-width bottom-section">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<h3>Summary</h3>
									</Grid>
									<Grid item xs={4}>
										<p className="summary-utilisation-key"><span className="key-icon assigned-utilisation-icon"></span>Assigned <span className="key-icon ondemand-utilisation-icon"></span>On-demand <span className="key-icon guest-utilisation-icon"></span>Guest</p>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '32px' }} >
									<Grid item xs={2}>
										<h4 className="summary-data-h4 highlighted-h4">346</h4>
										<p className="summary-data-text">Total bays</p>
									</Grid>
									<Grid item xs={2}>
										<h4 className="summary-data-h4">29%</h4>
										<p className="summary-data-text">Bay utilisation</p>
									</Grid>
									<Grid item xs={8}>
										<h4 className="summary-data-h4">Saturday</h4>
										<p className="summary-data-text">Highest utilisation day</p>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '35px' }}>
									<Grid item xs={12}>
										<img className="summary-graph-placeholder" src={summaryGraphImg} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3>On-demand parking</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '32px' }} >
									<Grid item xs={4}>
										<img className="ondemand-piechart-placeholder" src={ondemandPieChartImg} />
									</Grid>
									<Grid item xs={8}>
										<table id="utilisation-summary" className="ondemand-data">
											<tr>
												<th>Product</th>
												<th>On-demand bookings</th>
											</tr>
											<tr>
												<td><span className="daily-parking-key"></span><strong>Daily Parking Free</strong></td>
												<td>1,030</td>
											</tr>
											<tr>
												<td><span className="hourly-parking-key"></span><strong>Hourly Parking Free</strong></td>
												<td>300</td>
											</tr>
										</table>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3>Shuffle users</h3>
									</Grid>
								</Grid>
								<Grid container spacing={1} style={{ paddingTop: '32px' }} >
									<Grid item xs={6}>
										<img className="users-barchart-placeholder" src={usersBarChartImg3} />
									</Grid>
									<Grid item xs={6}>
										<table id="utilisation-summary" className="user-data">
											<tr>
												<th><strong>Team</strong></th>
												<th>Monthly active users</th>
												<th>Assigned bays</th>
												<th>On-demand bookings</th>
											</tr>
											<tr>
												<td><strong>Operations team</strong></td>
												<td>29</td>
												<td>45</td>
												<td>0</td>
											</tr>
										</table>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3>Download reports</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} style={{ paddingTop: '12px' }} >
									<Grid item xs={4}>
										<p className="download-instructions">Download a detailed report of all parking transactions. Use the filters to customise your data, then click export. </p>
										<img className="download-icon" src={downloadIcon} />
									</Grid>
									<Grid item xs={1}>
									</Grid>
									<Grid item xs={7}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<InputLabel className="select-heading">Choose a report</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													  MenuProps={{
														anchorOrigin: {
														  vertical: "bottom",
														  horizontal: "left"
														},
														transformOrigin: {
														  vertical: "top",
														  horizontal: "left"
														},

													  }}
												  value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">Example report name here</MenuItem>
													<MenuItem value="20">Example report name here</MenuItem>
													<MenuItem value="30">Example report name here</MenuItem>
													<MenuItem value="40">Example report name here</MenuItem>
													<MenuItem value="50">Example report name here</MenuItem>
													<MenuItem value="60">Example report name here</MenuItem>
												 </Select>
											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>
												<p className="input-label">Start Date</p>
												<TextField id="outlined-basic" defaultValue="6 September 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
												InputProps={{
												className: "shuffleInputIcon",
												  endAdornment: (
													<InputAdornment position="end">
													  <img className="input-calendar-icon" src={calendarIcon} />
													</InputAdornment>
												   )
												  }} />
											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>
												<p className="input-label">End Date</p>
												<TextField id="outlined-basic" defaultValue="9 September 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
												InputProps={{
												className: "shuffleInputIcon",
												  endAdornment: (
													<InputAdornment position="end">
													  <img className="input-calendar-icon" src={calendarIcon} />
													</InputAdornment>
												   )
												  }} />
											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>
												<InputLabel className="select-heading">Departments/teams</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													  MenuProps={{
														anchorOrigin: {
														  vertical: "bottom",
														  horizontal: "left"
														},
														transformOrigin: {
														  vertical: "top",
														  horizontal: "left"
														},

													  }}
												  value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">Australia Resources &gt; Operations</MenuItem>
													<MenuItem value="20">Team 1</MenuItem>
													<MenuItem value="30">Team 2</MenuItem>
													<MenuItem value="40">Team 3</MenuItem>
												 </Select>

											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>
												<InputLabel className="select-heading">Role</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													  MenuProps={{
														anchorOrigin: {
														  vertical: "bottom",
														  horizontal: "left"
														},
														transformOrigin: {
														  vertical: "top",
														  horizontal: "left"
														},

													  }}
												  value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">All roles</MenuItem>
													<MenuItem value="20">Role 1</MenuItem>
													<MenuItem value="30">Role 2</MenuItem>
													<MenuItem value="40">Role 3</MenuItem>
												 </Select>
											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>
												<InputLabel className="select-heading">Format</InputLabel>
												<Select className="shuffle-select"
													IconComponent={ExpandMoreIcon}
													  MenuProps={{
														anchorOrigin: {
														  vertical: "bottom",
														  horizontal: "left"
														},
														transformOrigin: {
														  vertical: "top",
														  horizontal: "left"
														},

													  }}
												  value="10" variant="outlined" margin="dense" fullWidth>
													<MenuItem value="10" className="selectBold">CSV</MenuItem>
													<MenuItem value="20">Other</MenuItem>
													<MenuItem value="30">Other</MenuItem>
												 </Select>
											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>

											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px' }}>

											</Grid>
											<Grid item xs={6} style={{ paddingTop: '25px', textAlign: 'right' }}>
												<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Export</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="utilisation-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

