import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import OperationsHeader from "../components/operations-header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import tickIcon from '../assets/svg/ws-sml-icon-tick-wht.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import gateIcon from '../assets/svg/icon-gate.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
		maxWidth: '200px',
		padding: '7px 22px',
		boxShadow: 'none',
	},
	shuffleButtonSml: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '0.875rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '0',
		paddingLeft: '0',
		maxWidth: '120px',
		paddingBottom: '7px',
	},
	reservedButtonSml: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#ffffff',
		backgroundColor: '#009273',
		fontSize: '0.875rem',
		width: 'auto',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '8px',
		paddingLeft: '8px',
		marginRight: '10px',
		marginTop: '8px',
	},
	reservedButtonSmlOL: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#525252',
		backgroundColor: '#ffffff',
		fontSize: '0.875rem',
		width: 'auto',
		borderRadius: '4px',
		letterSpacing: '0',
		paddingRight: '8px',
		paddingLeft: '8px',
		marginRight: '10px',
		marginTop: '8px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
		backgroundColor: '#ffffff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ffffff',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
		backgroundColor: '#ff0000',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#ff0000',
		},
	  },
	  shuffleAlert: {
		  backgroundColor: '#009273',
		  color: 'white',
		  borderRadius: '10px',
		  top: '10px',
		  position: 'fixed',
		  padding: '14px 16px',
	  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<Box className={classes.shuffleAlert} zIndex="tooltip" id="alert-modal">
				<p className="success-text">Latrobe Street Exit has been opened.</p>
			</Box>
			
			<OperationsHeader />

			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">
							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Force gate open</a></h2>
								</Grid>
							</Grid>
							
							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p>Force a car park gate to open remotely.</p>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper guest-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel larger-space">
								<Grid container spacing={0}>
									<Grid item xs={4}>
										<div className="operations-icon-block">
											<img className="force-gate-icon-lrg" src={gateIcon} />
											<h4 className="">Select a gate to open</h4>
										</div>
									</Grid>
									<Grid item xs={8}>
										<div className="row-wrapper">
										<InputLabel className="select-heading">Location</InputLabel>
										<Select className="shuffle-select"
											IconComponent={ExpandMoreIcon}
											MenuProps={{
											  anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											  },
											  transformOrigin: {
												vertical: "top",
												horizontal: "left"
											  },
										
											}}
										value="10" variant="outlined" margin="dense" fullWidth>
											<MenuItem value="10" className="selectBold">Select...</MenuItem>
											<MenuItem value="20">Option 1</MenuItem>
											<MenuItem value="30">Option 2</MenuItem>
										 </Select>
										</div>
										
										<div className="row-wrapper">
										 <InputLabel className="select-heading">Select a gate</InputLabel>
										 <Select className="shuffle-select"
											 IconComponent={ExpandMoreIcon}
											 MenuProps={{
											   anchorOrigin: {
												 vertical: "bottom",
												 horizontal: "left"
											   },
											   transformOrigin: {
												 vertical: "top",
												 horizontal: "left"
											   },
										 
											 }}
										 value="10" variant="outlined" margin="dense" fullWidth>
											 <MenuItem value="10" className="selectBold">Select...</MenuItem>
											 <MenuItem value="20">Gate 1</MenuItem>
											 <MenuItem value="30">Gate 2</MenuItem>
										  </Select>
										</div>
										
										<div className="row-wrapper">
										<h5 className="select-heading">Select a reason</h5>
										<FormControl component="fieldset">
										  <RadioGroup className="modal-checkboxes-gate">
											<div className="left-column">
												<FormControlLabel value="option1" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 1"
													labelPlacement="end" />
													
												<FormControlLabel value="option2" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 2"
													labelPlacement="end" />
												
												<FormControlLabel value="option3" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 3"
													labelPlacement="end" />
											</div>
											<div className="right-column">
												<FormControlLabel value="option4" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 4"
													labelPlacement="end" />
												
												<FormControlLabel value="option5" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 5"
													labelPlacement="end" />
												
												<FormControlLabel value="option6" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Reason 6"
													labelPlacement="end" />
											</div>
										  </RadioGroup>
										</FormControl>
										</div>
										
										<div className="row-wrapper-last">
										<p className="input-label">Add optional notes <span className="optional-text">(optional)</span></p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
										</div>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className="content-panel tight">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3>Who are you opening the gate for?</h3>
									</Grid>

									<Grid item xs={5} id="mobile-col-01">
										<p className="input-label">Name</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
									</Grid>
									<Grid item xs={2} className="desktop-only" id="mobile-col-02">
									</Grid>
									<Grid item xs={5} id="mobile-col-04">
										<p className="input-label">Licence plate <span className="optional-text">(optional)</span></p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
									</Grid>

									<Grid item xs={5} id="mobile-col-03">
										<p className="input-label">Mobile phone number</p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
									</Grid>
									<Grid item xs={2} className="desktop-only" id="mobile-col-05">
									</Grid>
									<Grid item xs={5} id="mobile-col-06">
										<p className="input-label">Company name <span className="optional-text">(optional)</span></p>
										<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '40px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6} className="desktop-only">
										<Box display="flex" justifyContent="flex-start">
											<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large" >Cancel</Button>
										</Box>
									</Grid>
									<Grid item xs={6} className="mobile-fullwidth-button">
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" disabled>Open gate</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="guest-section light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

