import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { RootState } from '../../store'
import { OrganisationType } from '../../common/types'

export const SET_LOGGED_ON = 'SET_LOGGED_ON'
export const SET_LOGGED_OFF = 'SET_LOGGED_OFF'

export const GET_WHO_AM_I_REQUEST = 'GET_WHO_AM_I_REQUEST'
export const GET_WHO_AM_I_SUCCESS = 'GET_WHO_AM_I_SUCCESS'
export const GET_WHO_AM_I_FAILURE = 'GET_WHO_AM_I_FAILURE'

export interface Authority {
  authority: string
}

export interface LogOnUser {
  id: string
  email: string
  providerId: string
  authorities: Authority[]
  enabled: boolean
  operatorIds: string[]
  name: string
  token: string
  organisationId: string
  organisationType: OrganisationType
}

export interface AuthState {
  initialized: boolean
  loggedIn: boolean
  loading: boolean
  authoritiesLoaded: boolean
  user: firebase.User | null
  error: string | null
  currentUser: LogOnUser | null
}

interface SetLoggedOnAction {
  type: typeof SET_LOGGED_ON
  payload: {
    user: firebase.User
  }
}

interface SetLoggedOffAction {
  type: typeof SET_LOGGED_OFF
}

/**
 * GET on the whoami end point to retrieve admin(carpark tenant) user object
 */
interface GetWhoAmIRequestAction {
  type: typeof GET_WHO_AM_I_REQUEST
}

interface GetWhoAmISuccessAction {
  type: typeof GET_WHO_AM_I_SUCCESS
  payload: {
    currentUser: LogOnUser
  }
}

interface GetWhoAmISuccessFailure {
  type: typeof GET_WHO_AM_I_FAILURE
  payload: {
    error: string
  }
}

export type AuthActionTypes = SetLoggedOnAction | SetLoggedOffAction | GetWhoAmIRequestAction | GetWhoAmISuccessAction | GetWhoAmISuccessFailure

export const selectCurrentFirebaseUser = (state: RootState) => (state.authReducer.loggedIn ? state.authReducer.user : null)
export const selectIsLoggedOn = (state: RootState) => [state.authReducer.initialized, state.authReducer.loggedIn]
export const selectCurrentUser = (state: RootState) => (state.authReducer.loggedIn && state.authReducer.currentUser ? state.authReducer.currentUser : null)
export const isCompletedLogin = (state: RootState) => (state.authReducer.loggedIn && state.authReducer.currentUser && state.authReducer.user ? true : false)
export const selectCurrentOrganisationId = (state: RootState) =>
  state.authReducer.loggedIn && state.authReducer.currentUser ? state.authReducer.currentUser.organisationId : null

export const selectAuthError = (state: RootState) => state.authReducer.error
