import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import OnDemandHeader from "../components/on-demand-header";
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import reservedIcon from '../assets/svg/ws-b2b-icon-reserved-2.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0px',
		maxWidth: '200px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	gridTopMargin: {
		paddingTop: '15px',
	},
	gridBottomMargin: {
		paddingTop: '20px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OnDemandHeader />
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2 no-padding"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Book on-demand parking</a></h2>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper assigned-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">

							<Grid item xs={12} className="content-panel" style={{ minHeight: '505px' }}>
								<Grid container spacing={0}>
									<Grid item xs={4}>
										<div className="assigned-icon-block">
											<img className="assigned-icon who-booking-for" src={manageParkersIcon} />
											<h4 className="">Who are you booking for?</h4>
										</div>
									</Grid>
									<Grid item xs={8}>
										<p className="search-users-title"><strong>Select a staff member</strong></p>
										<TextField id="outlined-basic" defaultValue="Search by name or email address" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										InputProps={{
											className: "shuffleInput",
											  startAdornment: (
												<InputAdornment position="start">
												  <img className="search-field-icon" src={searchFieldIcon} />
												</InputAdornment>
											   )
											  }} />
										<p className="search-users-text">If this staff member isn’t registered for Shuffle, <a href="">add them now</a> or <a href="">book guest parking</a> instead.</p>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} style={{ paddingTop: '20px' }}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-start">
											<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large" >Cancel</Button>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" disabled>Book</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="position-bottom light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

