import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import OperationsHeader from "../components/operations-header";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import searchFieldIcon from '../assets/svg/ws-sml-icon-search-field.svg';
import chevronLeftGreyIcon from '../assets/svg/ws-sml-icon-chevronleft-grey.svg';
import chevronRightIcon from '../assets/svg/ws-sml-icon-chevronright.svg';
import gateIcon from '../assets/svg/icon-gate.svg';
import usersIcon from '../assets/svg/ws-b2b-icon-staff.svg';

import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OperationsHeader />
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Control centre</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper full-width">
								<Grid item xs={12}>
									<TextField id="outlined-basic" defaultValue="Search by name or email address" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										InputProps={{
											className: "shuffleInput",
  											startAdornment: (
    											<InputAdornment position="start">
      											<img className="search-field-icon" src={searchFieldIcon} />
    											</InputAdornment>
   											)
  											}} />
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={0}>
									<Grid item xs={12} className="landing">
										<img className="control-users-icon" src={usersIcon} />
										<p className="no-users">Search for a user to update their entry<br /> 
										status or open a gate for them.</p>
									</Grid>
								</Grid>
							</Grid>
							<div className="force-gate-open-wrapper">
								<img className="force-gate-icon" src={gateIcon} />
								<p>If you are unable to locate a specific user you can force a gate open.<br />
								<a href="#">Force gate open</a></p>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="ondemand-section light-bg">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

