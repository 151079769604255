import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import addIcon from '../assets/svg/ws-ui-icon-add-white.svg';
import closeIcon from '../assets/svg/ws-ui-icon-close-x-grey.svg';
import calendarIcon from '../assets/svg/ws-ui-icon-calendar-grey.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		maxWidth: '204px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
		paddingLeft: '0px',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
	formModal: {
		background: 'white',
		padding: '0',
		borderRadius: '4px',
		maxWidth: '785px',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">

			<div className="modalOverlayBG">
				<Box className={classes.formModal}>
					<Container maxWidth="lg" disableGutters={true} className="form-modal-styles">

						<div className="scrolling-checkbox-wrapper">

						<Grid container spacing={2}>
							<Grid item xs={12} style={{paddingBottom: '0px'}}>
								<h3 className="modal-h3 larger-text">Add block out date</h3>
							</Grid>
							<Grid item xs={6} style={{paddingTop: '0px'}}>
								<p className="input-label">Start block out</p>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField id="outlined-basic" defaultValue="6 September 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
											InputProps={{
											className: "shuffleInputIcon",
										  	endAdornment: (
												<InputAdornment position="end">
											  	<img className="input-calendar-icon" src={calendarIcon} />
												</InputAdornment>
										   	)
										  	}} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6} style={{paddingTop: '0px'}}>
								<p className="input-label">End block out</p>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField id="outlined-basic" defaultValue="6 September 2021" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
										InputProps={{
										className: "shuffleInputIcon",
										  endAdornment: (
											<InputAdornment position="end">
											  <img className="input-calendar-icon" src={calendarIcon} />
											</InputAdornment>
										   )
										  }} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{paddingBottom: '0px', paddingTop: '0px'}}>
								<FormGroup>
								  <FormControlLabel control={<Checkbox defaultChecked />} label="All Day" />
								</FormGroup>
							</Grid>
						</Grid>
						<Grid container spacing={2} style={{marginTop: '32px'}}>
							<Grid item xs={6}>
								<p className="checkbox-label">Which locations does this block out apply to?</p>
								<FormGroup>
								  <FormControlLabel control={<Checkbox />} label="George Street Office" />
								  <FormControlLabel control={<Checkbox />} label="Pitt Street Office" />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<p className="checkbox-label">Which users does this block out apply to?</p>
								<FormGroup>
									<FormControlLabel className="" control={<Checkbox />} label="All departments &amp; teams" />
									<FormControlLabel className="" control={<Checkbox />} label="Australian Resources Retail" />
									<FormControlLabel className="child-checkbox checked" control={<Checkbox defaultChecked />} label="Retail mobile sales team" />
									<FormControlLabel className="child-checkbox" control={<Checkbox />} label="Office staff" />
									<FormControlLabel className="child-checkbox" control={<Checkbox />} label="Operations team" />
									<FormControlLabel className="child-checkbox checked" control={<Checkbox defaultChecked />} label="Finance team" />
									<FormControlLabel className="" control={<Checkbox />} label="Australian Resources Enterprise" />
									<FormControlLabel className="child-checkbox" control={<Checkbox />} label="Retail mobile sales team" />
									<FormControlLabel className="child-checkbox" control={<Checkbox />} label="Office staff" />
									<FormControlLabel className="child-checkbox" control={<Checkbox />} label="Finance team" />
								</FormGroup>
							</Grid>
						</Grid>

						</div>

						<Grid container spacing={2} direction="row" alignItems="center" className="scrolling-checkbox-footer">
							<Grid item xs={3}>
								<Box display="flex" justifyContent="flex-start">
									<Button className={classes.shuffleButton} variant="outlined" color="primary" size="large">Cancel</Button>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" justifyContent="center">
									<p className="modal-disclaimer-text">This block out won’t impact assigned parkers, <br /> including those who share their bay.</p>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box display="flex" justifyContent="flex-end">
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Add</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</div>

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="selected  has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li><a href="#" className="main-nav-item">Utilisation</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">PS</a>
							<ul className="dropdown pull-left">
								<li><a href="#">Paul Sidwell <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
				</div>

			</div>
			<div className="page-content-wrapper">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Block out dates</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p>Schedule dates when on demand parking will be unavailable.</p>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper right-aligned">
								<Grid item xs={12}>
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" startIcon={<img className="buttonIcon" src={addIcon} />} >Add block out date</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-10">Upcoming dates</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<div className="location-card">
											<p className="location-title">10 September 2021</p>
											<p className="location-product-count"><span className="icon-time">All Day</span> <span className="icon-teams">All Teams</span> <span className="icon-location">George St Office</span></p>
											<a href="#" className="close-card"><img className="close-x" src={closeIcon} /></a>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className="location-card">
											<p className="location-title">22 - 23 September 2021</p>
											<p className="location-product-count"><span className="icon-time">All Day</span> <span className="icon-teams">3 Teams</span> <span className="icon-location">George St Office</span></p>
											<a href="#" className="close-card"><img className="close-x" src={closeIcon} /></a>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className="location-card">
											<p className="location-title">1 October 2021</p>
											<p className="location-product-count"><span className="icon-time">9:00am - 7:00pm</span> <span className="icon-teams">All Teams</span> <span className="icon-location">George St Office</span></p>
											<a href="#" className="close-card"><img className="close-x" src={closeIcon} /></a>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="ondemand-section light-bg">

			<div id="footer-text">
				<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
			</div>

		</div>

	</>
}

