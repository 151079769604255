import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import wilsonLogo from '../assets/svg/Wilson-Parking-Logo.svg';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/images/your-logo.png';
import notVisibleIcon from '../assets/svg/ws-ui-icon-not-visible-gry.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#ffffff',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		padding: '7px 22px',
	},
	selfRegModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '185px',
		width: '705px',
		textAlign: 'left',
		boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
	},
}));

export default function CenteredGrid() {
	  const classes = useStyles();
	return <>
		<Container className="selfRegBG">

			<div id="header-wrapper" className="login-header">

				<div id="header" className="main-nav self-reg-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
				</div>

			</div>

			<Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
				<Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12}>
							<h2 className="self-reg-h2">Register for parking at [Company]</h2>
							<p className="self-reg-required-text">*All fields required</p>
						</Grid>
						<Grid item xs={12}>
							<h3 className="self-reg-h3">About you</h3>
						</Grid>
						<Grid item xs={6}>
							<p className="input-label">First name</p>
							<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
								
							<p className="input-label">Email address</p>
							<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
								
							<InputLabel className="select-heading">Role</InputLabel>
							<Select className="shuffle-select"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">Manager</MenuItem>
								<MenuItem value="20">Option 2</MenuItem>
								<MenuItem value="30">Option 3</MenuItem>
								<MenuItem value="40">Option 4</MenuItem>
							 </Select>
						</Grid>
						
						<Grid item xs={6}>
							<p className="input-label">Surname</p>
							<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
							
							<p className="input-label">Mobile phone number</p>
							<TextField id="outlined-basic" InputProps={{ className: "shuffleInput" }} defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
								
							<InputLabel className="select-heading">Office location</InputLabel>
							<Select className="shuffle-select"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">Perth</MenuItem>
								<MenuItem value="20">Sydney</MenuItem>
								<MenuItem value="30">Melbourne</MenuItem>
								<MenuItem value="40">Adelaide</MenuItem>
							 </Select>
						</Grid>
						<Grid item xs={12}>
							<h3 className="self-reg-h3">Your team</h3>
						</Grid>
						<Grid item xs={6}>
							<InputLabel className="select-heading">Choose your department</InputLabel>
							<Select className="shuffle-select"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">Company Retail</MenuItem>
								<MenuItem value="20">Option 2</MenuItem>
								<MenuItem value="30">Option 3</MenuItem>
								<MenuItem value="40">Option 3</MenuItem>
							 </Select>
						</Grid>
						<Grid item xs={6}>
							<InputLabel className="select-heading">Choose your team</InputLabel>
							<Select className="shuffle-select"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">Retail mobile sales team</MenuItem>
								<MenuItem value="20">Option 2</MenuItem>
								<MenuItem value="30">Option 3</MenuItem>
								<MenuItem value="40">Option 3</MenuItem>
							 </Select>
						</Grid>
						<Grid item xs={9} style={{paddingTop: '60px'}}>
						</Grid>
						<Grid item xs={3} style={{paddingTop: '60px'}}>
							<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Register Now</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<div id="footer" className="login-page dark-bg">

				<div id="footer-text">
					<p>&copy; Wilson Parking 2021. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				</div>

			</div>

		</Container>



	</>
}

