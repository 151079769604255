import { RootState } from '../store'
import { AxiosRequestConfig } from 'axios'
import { AUTHORIZATION_HEADER, BEARER_PREFIX, SERVER_API_KEY_HEADER } from '../config/constants'

export function extractTenant(hostname: string): string {
  const pieces = hostname.split('.')
  if (pieces.length > 1) {
    return pieces[0]
  } else {
    return hostname
  }
}

export const findConfiguration = (state: RootState) => {
  const appConfig = state.appConfigReducer
  const shuffleApiConfig = appConfig.shuffleApiConfig
  const tenantConfig = appConfig.tenantConfig
  const operatorId = tenantConfig?.config.operatorId
  const shuffleUrl = shuffleApiConfig?.shuffleApiUrl
  const tenantId = tenantConfig?.tenant.id
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = state.authReducer.user
  const currentUser = state.authReducer.user
  const carParkTenantId = state.authReducer.currentUser?.organisationId
  // Check for mandatory config
  if (shuffleUrl == null) throw Error('url missing from shuffleApiConfig')
  if (tenantId == null) throw Error('tenantId missing from tenantConfig')
  if (apiKey == null) throw Error('apiKey missing from tenantConfig')
  if (operatorId == null) throw Error('operatorId missing from tenantConfig')
  if (carParkTenantId == null) throw Error('carParkTenantId missing from tenantConfig')
  return { shuffleUrl, carParkTenantId, tenantId, apiKey, operatorId, firebaseUser, currentUser }
}

export const buildAxiosConfig = async (state: RootState, params: any): Promise<AxiosRequestConfig> => {
  const appConfig = state.appConfigReducer
  const shuffleApiConfig = appConfig.shuffleApiConfig
  const tenantConfig = appConfig.tenantConfig
  const shuffleUrl = shuffleApiConfig?.shuffleApiUrl
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = state.authReducer.user
  if (shuffleUrl == null) throw Error('url missing from shuffleApiConfig')
  if (apiKey == null) throw Error('apiKey missing from tenantConfig')
  if (firebaseUser != null) {
    const idToken = await firebaseUser.getIdToken()
    return {
      baseURL: shuffleUrl,
      headers: {
        [SERVER_API_KEY_HEADER]: apiKey,
        [AUTHORIZATION_HEADER]: BEARER_PREFIX + idToken,
      },
      params: params,
    }
  } else {
    return {
      baseURL: shuffleUrl,
      headers: {
        [SERVER_API_KEY_HEADER]: apiKey,
      },
    }
  }
}
