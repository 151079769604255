import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      light: 'ff9f9f',
      main: '#ff0000',
      contrastText: '#ffffff',
      dark: '#9c1937',
    },
    secondary: {
      main: '#979797',
    },
    background: {
      default: '#ffffff',
    },
    action: {
      disabledBackground: '#e3e3e3',
      disabled: '#979797',
    },
  },
  typography: {
    fontFamily: 'Untitled Sans, Arial',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '3px',
          color: '#ffffff',
          '&.Mui-disabled': {
            color: '#979797',
          },
          '&.OutLined': {
            color: '#ff0000',
          },
        },

        outlined: {
          color: '#ff0000',
        },
        disableElevation: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#979797',
        },
        colorSecondary: {
          '&$checked': {
            color: '#ff0000',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '5px',
          fontSize: '0.875rem',
          borderColor: 'green',
          backgroundColor: 'rgb(255, 255, 255)',
          '&:hover': {
            backgroundColor: 'rgb(255, 255, 255)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'rgb(255, 255, 255)',
            },
          },
          '&.Mui-focused': {
            backgroundColor: 'rgb(255, 255, 255)',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#E3E3E3',
            padding: '15px',
          },
        },
      },
    },
  },
})
