import React from 'react'
import './App.css'
import RoutesUtil from './routes-util'

interface IProps {
  tenantCode: string
}

const App: React.FC<IProps> = (props: IProps) => {
  return <RoutesUtil />
}

export default App
