import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import wilsonLogo from '../assets/svg/Wilson-Parking-Logo.svg';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#979797',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		padding: '7px 22px',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
	shuffleAlert: {
		backgroundColor: '#ef3f1c',
		color: 'white',
		borderRadius: '4px',
		top: '10px',
		position: 'absolute',
		padding: '14px 16px',
	}
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="loginBG">

			<Box className={classes.shuffleAlert} zIndex="tooltip">
				<p className="warning-text">The email address or password entered are incorrect. Please try again.</p>
			</Box>

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="login-nav">
					<div className="logo-holder row mobile-only">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
					</div>
					<a id="wilson-parking-logo" href="#" className="desktop-only"><img src={wilsonLogo} alt="Wilson parking" /></a>
				</div>

			</div>

			<Box className={classes.loginModal}>
				<Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12}>
							<h3 className="login-h3 desktop-only">Welcome to</h3>
							<h3 className="login-h3 mobile-only">Welcome to Shuffle</h3>
							<img className="shuffle-login-logo desktop-only" src={shuffleLogo} alt="Shuffle" />
						</Grid>
						<Grid item xs={12}>
							<p className="input-label">Email address</p>
							<TextField inputProps={{ className: "shuffleInput" }} id="outlined-basic" defaultValue="samuel.mcmillan@company.com" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<p className="input-label">Password</p>
							<TextField inputProps={{ className: "shuffleInput" }} id="outlined-basic" defaultValue="" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" disabled>Login</Button>
							<a className="password-link" href="#">I forgot my password</a>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<div id="footer" className="login-page dark-bg">

				<div id="footer-text">
					<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
					<p className="footer-version-text">V d99c580</p>
				</div>

			</div>

		</Container>

	</>
}

