import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from '@mui/material/Button';
import wilsonLogo from '../assets/svg/Wilson-Parking-Logo.svg';
import notVisibleIcon from '../assets/svg/ws-ui-icon-not-visible-gry.svg';
import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';


const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '1rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		padding: '7px 22px',
		boxShadow: 'none',
	},
	loginModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		position: 'absolute',
		top: '194px',
		width: '367px',
		textAlign: 'center',
	},
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="loginBG">

			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="login-nav">
					<div className="logo-holder row mobile-only">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
					</div>
					<a id="wilson-parking-logo" href="#" className="desktop-only"><img src={wilsonLogo} alt="Wilson parking" /></a>
				</div>

			</div>

			<Box className={classes.loginModal}>
				<Container maxWidth="lg" disableGutters={true} className="login-modal-styles base-button">
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12}>
							<h3 className="login-h3">Choose a password</h3>
						</Grid>
						<Grid item xs={12}>
							<p className="input-label">New password</p>
							<TextField id="outlined-basic" defaultValue="••••••••" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
							InputProps={{
							className: "shuffleInput",
							  endAdornment: (
								<InputAdornment position="end">
								  <img className="password-field-icon" src={notVisibleIcon} />
								</InputAdornment>
							   )
							  }} />
						</Grid>
						<Grid item xs={12}>
							<p className="input-label">Repeat new password</p>
							<TextField id="outlined-basic" defaultValue="••••••••" InputLabelProps={{ className: "shuffleLabel" }} variant="outlined" margin="dense" fullWidth
							InputProps={{
							className: "shuffleInput",
							  endAdornment: (
								<InputAdornment position="end">
								  <img className="password-field-icon" src={notVisibleIcon} />
								</InputAdornment>
							   )
							  }} />
						</Grid>
						<Grid item xs={12}>
							<p className="password-tips">A FEW TIPS:</p>
							<ul className="password-tips-list password-accepted">
								<li>Use between 8 and 16 characters</li>
								<li>At least one lowercase character</li>
								<li>At least one uppercase character</li>
								<li>At least one number</li>
								<li>At least one special character (e.g. ?#!)</li>
								<li>The same password entered twice</li>
							</ul>
						</Grid>
						<Grid item xs={12}>
							<Button className={classes.shuffleButton} variant="contained" color="primary" size="large">Set password</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<div id="footer" className="login-page dark-bg">

				<div id="footer-text">
					<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
					<p className="footer-version-text">V d99c580</p>
				</div>

			</div>

		</Container>



	</>
}

