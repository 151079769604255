import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import OperationsHeader from "../components/operations-header";
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import backIcon from '../assets/svg/ws-ui-icon-arrow-back-grey.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';
import shortArrow from '../assets/svg/modal-arrow-short.svg';
import closeIcon from '../assets/svg/ws-ui-icon-close-x-black.svg';
import vertArrow from '../assets/svg/modal-arrow-vertical.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	shuffleButton: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		boxShadow: 'none',
	},
	shuffleButtonModal: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: '#ff0000',
		fontSize: '1rem',
		width: '100%',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
	},
	shuffleButtonLeft: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: 'white',
		fontSize: '0.875rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		minWidth: '160px',
	},
	shuffleButtonRight: {
		fontFamily: 'Untitled Sans',
		textTransform: 'none',
		color: 'white',
		fontSize: '0.875rem',
		width: '100%',
		marginTop: '15px',
		marginBottom: '5px',
		borderRadius: '4px',
		letterSpacing: '0',
		padding: '7px 0',
		minWidth: '140px',
	},
	shuffleLabel: {
		backgroundColor: '#ffffff',
	},
	gridTopMargin: {
		paddingTop: '17px',
	},
	gridSmlTopMargin: {
		paddingTop: '15px',
	},
	formModal: {
		background: 'white',
		padding: '40px',
		borderRadius: '4px',
		width: '590px',
		maxWidth: '590px',
		textAlign: 'left',
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
		backgroundColor: '#ffffff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ffffff',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
		backgroundColor: '#ff0000',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#ff0000',
		},
	  },
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Container className="adminBG">
			<OperationsHeader />
				
			<div className="modalOverlayBG">
				<Box className={classes.formModal}>
					<Container maxWidth="lg" disableGutters={true} className="form-modal-styles change-status-modal">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<img className="icon-close-modal mobile-only" src={closeIcon} alt="close" />
								<h3 className="modal-h3 larger-text">Change entry status</h3>
								<p>If a user is unable to access the car park,<br />change their status to match their location.</p>
									<div className="status-radios">
										<div className="status-radio-wrapper">
											<FormControl component="fieldset">
											  <RadioGroup className="modal-checkboxes-entry five-columns">
												<FormControlLabel value="option1" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													className="radio-checked" checked
													label="Outside"
													labelPlacement="bottom" />
											
												<FormControlLabel value="option2" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Transiting in"
													labelPlacement="bottom" />
													
												<FormControlLabel value="option3" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Inside"
													labelPlacement="bottom" />
												
												<FormControlLabel value="option4" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Transiting out"
													labelPlacement="bottom" />
												
												<FormControlLabel value="option5" control={<Radio
													className={classes.root}
													disableRipple
													color="default"
													checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
													icon={<span className={classes.icon} />}
													/>}
													label="Outside"
													labelPlacement="bottom" />
											
											  </RadioGroup>
											</FormControl>
											<div className="arrow-wrapper short-arrows">
												<img className="modal-arrow-short left-arrow" src={shortArrow} />
												<img className="modal-arrow-short mid-left-arrow" src={shortArrow} />
												<img className="modal-arrow-short mid-right-arrow" src={shortArrow} />
												<img className="modal-arrow-short right-arrow" src={shortArrow} />
											</div>
											
											<div className="mob-arrow-wrapper vert-arrows mobile-only">
												<img className="modal-arrow-vert arrow-one" src={vertArrow} />
												<img className="modal-arrow-vert arrow-two" src={vertArrow} />
												<img className="modal-arrow-vert arrow-three" src={vertArrow} />
												<img className="modal-arrow-vert arrow-four" src={vertArrow} />
											</div>
										</div>
									</div>
								<p className="entry-status-text">The user is outside of the car park.</p>
							</Grid>
						</Grid>
						
						<Grid container spacing={2} style={{ paddingTop: '20px' }} className="anchored-wrapper">
							<Grid item xs={6} className="desktop-only">
								<Box display="flex" justifyContent="flex-start">
									<Button className={classes.shuffleButtonModal} variant="outlined" color="primary" size="large" style={{ width: '160px' }}>Cancel</Button>
								</Box>
							</Grid>
							<Grid item xs={6} className="mobile-fullwidth-button">
								<Box display="flex" justifyContent="flex-end">
									<Button className={classes.shuffleButton} variant="contained" color="primary" size="large" style={{ width: '160px' }}>Change</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</div>	
				
			<div className="page-content-wrapper ondemand-section">

				<Container className="page-content-header-wrapper">
					<Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
						<Grid item xs={3} className="left-col sidebar top-section">

							<Grid container spacing={0} className="responsive-title-wrapper">
								<Grid item xs={12}>
									<h2 className="on-demand-h2"><a className="back-icon" href="#"><img className="icon-back-arrow" src={backIcon} alt="back" />Sam McMillan</a></h2>
								</Grid>
							</Grid>

							<Grid container spacing={0} className="responsive-content-wrapper">
								<Grid item xs={12}>
									<p className="entry-status">Entry Status: Inside</p>
									<p className="entry-status-detail">The user is inside the correct car park area.</p>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="responsive-button-wrapper right-aligned">
								<Grid item xs={12} className="inline-buttons">
									<Button className={classes.shuffleButtonLeft} variant="contained" color="primary" size="large">Change entry status</Button>
									<Button className={classes.shuffleButtonRight} variant="outlined" color="secondary" size="large">Open gate</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<Container className="page-content-body-wrapper ondemand-section">
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item xs={9} className="right-col">
							<Grid item xs={12} className="content-panel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h3 className="padding-bottom-10 padding-top-5">On-demand booking</h3>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail first-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>User</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>Sam McMillan</td>
													</tr>
													<tr>
														<td>sam.mcmillan@company.com.au</td>
													</tr>
													<tr>
														<td>Australia Resources Retail (Office staff)</td>
													</tr>
													<tr>
														<td>Executive</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>
												
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="transaction-detail last-item">
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<h5>Booking</h5>
											</Grid>
											<Grid item xs={5}>
												<table id="transaction-detail-table">
													<tr>
														<td>George Street Office</td>
													</tr>
													<tr>
														<td>Staff Parking</td>
													</tr>
													<tr>
														<td>Wed 30 August 6:00am - Thu 31 August 6:00pm</td>
													</tr>
												</table>
											</Grid>
											<Grid item xs={3}>

											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>

		</Container>

		<div id="footer" className="position-bottom light-bg modal-open">

			<div id="footer-text">
				<p className="footer-copyright-text">&copy; Wilson Parking 2023. All Rights Reserved. <a href="#">Privacy Policy</a></p>
				<p className="footer-version-text">V d99c580</p>
			</div>

		</div>

	</>
}

