export const APP_CONFIG = 'tenant_admin'

export interface TenantCoreConfig {
  type: typeof APP_CONFIG
  apiPath: string
  jurisdictionCode: string
  infraCdnUrl: string
  operatorId: string
}

export interface TenantConfigTenant {
  id: string
  apiKey: string
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
}

export interface FirebaseAuthConfig {
  tenantId: string
}

export interface MapBoxConfig {
  token: string
  url: string
}

export interface TenantConfig {
  tenant: TenantConfigTenant
  config: TenantCoreConfig
  firebase: FirebaseConfig
  firebaseAuth: FirebaseAuthConfig
  mapBox: MapBoxConfig
}

export interface TenantConfig {
  tenant: TenantConfigTenant
  config: TenantCoreConfig
  firebase: FirebaseConfig
  firebaseAuth: FirebaseAuthConfig
  mapBox: MapBoxConfig
}

export interface ShuffleApiConfig {
  shuffleApiUrl: string
}

export interface AppConfigState {
  configured: boolean
  loading: boolean
  error: string | null
  tenantConfig: TenantConfig | null
  shuffleApiConfig: ShuffleApiConfig | null
}

export const TENANT_CONFIG_REQUEST = 'TENANT_CONFIG_REQUEST'
export const TENANT_CONFIG_SUCCESS = 'TENANT_CONFIG_SUCCESS'
export const TENANT_CONFIG_FAILURE = 'TENANT_CONFIG_FAILURE'
export const UPDATE_APP_CONFIG = 'UPDATE_CORE_CONFIG'

interface TenantConfigRequestAction {
  type: typeof TENANT_CONFIG_REQUEST
}

interface TenantConfigSuccessAction {
  type: typeof TENANT_CONFIG_SUCCESS
  payload: {
    data: TenantConfig
  }
}

interface TenantConfigFailureAction {
  type: typeof TENANT_CONFIG_FAILURE
  payload: {
    error: string
  }
}

interface updateAppConfig {
  type: typeof UPDATE_APP_CONFIG
  payload: {
    url: string
  }
}

export type AppConfigActionTypes =
  | TenantConfigRequestAction
  | TenantConfigSuccessAction
  | TenantConfigFailureAction
  | updateAppConfig
