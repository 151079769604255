import shuffleLogo from '../assets/svg/Shuffle-Logo.svg';
import yourLogo from '../assets/svg/your-logo.svg';
import manageParkersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import assignParkingIcon from '../assets/svg/ws-b2b-icon-assign-parking-2.svg';
import prodsCapacitiesIcon from '../assets/svg/ws-b2b-icon-car_park.svg';
import transactionsIcon from '../assets/svg/ws-b2b-icon-receipt-2.svg';
import bookParkingIcon from '../assets/svg/ws-b2b-icon-book_parking.svg';
import blockDatesIcon from '../assets/svg/ws-b2b-icon-block-out-dates.svg';
import selfRegIcon from '../assets/svg/ws-b2b-icon-toggle.svg';
import usersIcon from '../assets/svg/ws-b2b-icon-staff.svg';
import utilisationIcon from '../assets/svg/ws-b2b-icon-report_bar_chart-2.svg';
import guestIcon from '../assets/svg/ws-b2b-icon-consumer_cust.svg';
import assignIcon from '../assets/svg/ws-b2b-icon-reserved-2.svg';
import controlCentreIcon from '../assets/svg/ws-b2b-icon-operations.svg';
import hamburgerIcon from '../assets/svg/hamburger.svg';

export default function Header() {
return <>
			<div id="header-wrapper" className="login-header">

				<div id="top-nav-header">
					<div className="top-nav-holder">
						<ul id="shuffle-home-nav">
							<li className="selected"><a href="#" className="top-nav" id="shuffle-tab">Shuffle</a></li>
						</ul>
						<ul id="shuffle-top-nav">
							<li><a href="#" className="top-nav" id="support-tab">Support</a></li>
							<li><a href="#" className="top-nav" id="contact-tab">Contact Wilson Parking</a></li>
						</ul>
					</div>
				</div>

				<div id="header" className="main-nav">
					<div className="logo-holder row">
						<a id="shuffle-logo" href="#"><img src={shuffleLogo} alt="Shuffle" /></a>
						<a id="your-logo" href="#"><img src={yourLogo} alt="Your Logo" /></a>
					</div>
					<div className="nav-holder row">
						<ul id="shuffle-main-nav">
							<li><a href="#" className="main-nav-item">Home</a></li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Assigned</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon manage-parkers-icon" src={manageParkersIcon} />Manage Parkers</a></li>
										<li><a href="#"><img className="menu-icon assign-parking-icon" src={assignParkingIcon} />Assign parking</a></li>
									</ul>
							  </li>
							<li className="has-children">
								<a href="#" className="main-nav-item">On-demand</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
										<li><a href="#"><img className="menu-icon book-parking-icon" src={bookParkingIcon} />Book parking</a></li>
										<li><a href="#"><img className="menu-icon products-capacities-icon" src={prodsCapacitiesIcon} />Products and capacities</a></li>
										<li><a href="#"><img className="menu-icon block-out-dates-icon" src={blockDatesIcon} />Block out dates</a></li>
									</ul>
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Guest</a>
									<ul className="dropdown">
										<li><a href="#"><img className="menu-icon book-for-guest-icon" src={guestIcon} />Book for a guest</a></li>
										<li><a href="#"><img className="menu-icon transactions-icon" src={transactionsIcon} />Transactions</a></li>
									</ul>
							</li>
							<li className="has-children"><a href="#" className="main-nav-item">Users</a>
								<ul className="dropdown pull-left">
									<li><a href="#"><img className="menu-icon manage-all-users-icon" src={manageParkersIcon} />Manage all users</a></li>
								</ul>
							</li>
							<li className="selected has-children"><a href="#" className="main-nav-item">Operations</a>
								<ul className="dropdown pull-left">
									<li><a href="#"><img className="menu-icon control-centre-icon" src={controlCentreIcon} />Control centre</a></li>
								</ul>
							</li>
							<li className="has-children"><a href="#" className="main-nav-item">Utilisation</a>
							
							</li>
							<li className="has-children">
								<a href="#" className="main-nav-item">Settings</a>
									<ul className="dropdown pull-left">
										<li><a href="#"><img className="menu-icon self-reg-menu-icon" src={selfRegIcon} />Self registration link</a></li>
									</ul>
							</li>
						</ul>
					</div>
					<div className="user">
						<a id="user-loggedin" href="#">WP</a>
							<ul className="dropdown pull-left">
								<li><a href="#"><strong>Paul Sidwell</strong> <span className="user-email">paul@company.com.au</span></a></li>
								<li><a href="#">Logout</a></li>
							</ul>
					</div>
					<div className="mobile-menu">
						<img className="mobile-menu-icon" src={hamburgerIcon} />
					</div>
				</div>

			</div>
	</>
}